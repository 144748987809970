/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Field } from 'formik';
// eslint-disable-next-line import/no-cycle
import { FormFiles } from './index';

function IdentityVerification({ applicationStateDisabled }) {
  return <>
    <div className="form-text">
      KYC is required by law. The information must be complete and accurate.
    </div>
    <div className="form-text-wide form-text-wide--sm">
      We need detailed information about the company, its shareholders, beneficial owners, officers, and the group structure. Upload the requested documents below.
    </div>
    <div className="w-100 mt-5">
      <div>
        <div className="mt-5">
          <Field name="registrationDocument">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  Company registration
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  Certificate of incorporation/articles of association/equivalent, issued within the last 12 months. The document(s) must show the company name, business ID, registration date, company type, registered address, contact details, and a list of persons authorised to represent the company. If you do not have all of this information, indicate the reason why not.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : null}
                  multiple
                  label=""
                  uploadType="REGISTRATION_DOCUMENT"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>
        <div style={{ marginTop: 80 }}>
          <Field name="shareholders">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  Shareholders register
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  The shareholders register, issued within the last 12 months. This is a list of all active and former owners of the company’s shares. The details need to include each shareholder’s name, address, the number of shares they own, the class of shares held, date when they became or ceased to be a shareholder. If you do not have all of this information, indicate the reason why not.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : null}
                  multiple
                  label=""
                  uploadType="SHAREHOLDERS_REGISTER"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

        <div style={{ marginTop: 80 }}>
          <Field name="officerslist">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  A list of officers
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  This is a list of officers and directors at C-level.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : undefined}
                  multiple
                  label=""
                  uploadType="OFFICERS_LIST"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

        <div style={{ marginTop: 80 }}>
          <Field name="authorizationletter">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  A letter of authorization
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  A duly signed letter of authorisation for the person who acts on the company’s behalf towards Silta. The person filling out and submitting this form must be one of those authorised.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : undefined}
                  multiple
                  label=""
                  uploadType="AUTHORIZATION_LETTER"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

        <div style={{ marginTop: 80 }}>
          <Field name="structurechart">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  Ownership structure
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  The ownership structure, showing all ultimate beneficial owners of the company (with at least 25% of shares or control). The ownership structure must include the director’s confirmation that the information is true and accurate as of the date of submission.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : undefined}
                  multiple
                  label=""
                  uploadType="STRUCTURE_CHART"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

        <div style={{ marginTop: 80 }}>
          <Field name="groupstructure">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  Group structure (if applicable)
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  The group ownership structure, showing all parent and subsidiary companies with at least 25% of shares or control. Information about the percentage or shares or control held by different entities and/or shareholders in the group must be specified.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : undefined}
                  multiple
                  label=""
                  uploadType="GROUP_STRUCTURE"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

        <div style={{ marginTop: 80 }}>
          <Field name="sanctions">
            {({
              field,
              meta,
            }) => (
              <div className="mt-5">
                <div className="form-label">
                  Sanctions declarations
                </div>
                <div className="form-text-wide form-text-wide--sm" style={{ marginBottom: '17px' }}>
                  A duly signed declaration that neither you nor the company, any of the ultimate beneficial owners, any of the officers and directors of the company, any parent or subsidiary company, or any of the ultimate beneficial owners, officers and directors of the same are included on the sanctions list of SECO (Swiss State Secretariat for Economic Affairs) List or on the Consolidated United Nations Security Council Sanctions List.
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG or JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={meta.touched && meta.error ? 'error' : undefined}
                  multiple
                  label=""
                  uploadType="SANCTIONS_DECLARATIONS"
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>

      </div>
    </div>
  </>
}

export default IdentityVerification;
