import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { BsArrowLeftShort } from 'react-icons/bs';
import { RESET_PASSWORD_EMAIL } from '../queries';
import { FormText } from '../components/Form';
import CheckEmail from '../components/checkEmail';
import Logo from '../assets/images/icons/signin-logo.svg';
import ErrorAlert from '../components/ErrorAlert';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Invalid email format')
    .required('Email is required'),
});

function Forgot() {
  const [userEmail, setUserEmail] = useState('');
  const [submitForm, setSubmitForm] = useState(false);
  const [forgotError, setForgotError] = useState(false);
  // const history = useHistory();

  const [resetPassword] = useLazyQuery(RESET_PASSWORD_EMAIL, {
    onError: (error) => {
      setForgotError(error);
    },
    onCompleted: (data) => {
      if (data.resetPassword) {
        setSubmitForm(true);
      } else setForgotError('There was an error sending you the email, please try again later.');
    },
  });

  return (
    <section className="account">
      {!submitForm ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-5">
              <div className="w-100 text-center">
                <Link to="/">
                  <img src={Logo} alt="Silta logo" className="account__logo" />
                </Link>
                <h2 className="account__title">Forgot password?</h2>
                <p className="account__text">Enter your email and we&apos;ll send you reset instructions</p>
              </div>
              {forgotError && <ErrorAlert error={forgotError} />}
              <div className="w-100">
                <div>
                  <Formik
                    initialValues={{
                      email: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      resetPassword({
                        variables: {
                          email: values?.email,
                        },
                      });

                      setUserEmail(values?.email);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormText
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={
                            touched.email && errors.email ? 'error' : null
                          }
                          label="Email*"
                        />
                        <Button
                          type="submit"
                          variant="primary"
                          className="account__btn mt-4 mb-3"
                        >
                          Reset password
                        </Button>
                        <div className="my-3 text-center">
                          <div className="login-back w-100 d-flex align-items-center justify-content-center">
                            <Link to="/login">
                              <BsArrowLeftShort />
                              Back
                            </Link>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CheckEmail userEmail={userEmail} setSubmitForm={setSubmitForm} />
      )}
    </section>
  );
}

export default Forgot;
