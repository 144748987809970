/* eslint-disable */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import { Form, ProgressBar, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { isObject, map } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { useAuth, AUTH_TOKEN } from '../../providers/AuthProvider';
import { useParams } from 'react-router-dom';
import { UPLOAD_MULTIPLE_FILES, DELETE_FILE } from '../../queries';
import { ErrorMessage } from 'formik';
import ErrorLabel from '../ErrorMessage';
import { IoClose } from 'react-icons/io5';
import UploadIcon from '../../assets/images/icons/upload.svg';
import ImgUpload from '../../assets/images/icons/img-upload.svg';
import Completed from '../../assets/images/icons/checked.svg';
import UploadError from '../../assets/images/icons/upload-error.svg';
import { assertValidExecutionArguments } from 'graphql/execution/execute';

function FormFiles({ name, label, filesAccepted, className, multiple = false, accept, maxSize, uploadType, value, style, hint, disabled }) {
  const [completed, setCompleted] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(value || []);
  const [myFiles, setMyFiles] = useState([]);
  const { id: applicationId } = useParams();
  const { isAdmin } = useAuth();

  let initiated = false;

  useEffect(() => {
    if (value && !initiated) {
      initiated = true;
      setUploadedFiles(value);
    }
  }, [value]);

  useEffect(() => {

  }, [uploadedFiles])


  const [
    uploadFile,
    { loading: uploadingFile, error: uploadingError },
  ] = useMutation(UPLOAD_MULTIPLE_FILES, {
    onCompleted: (data) => {
      setCompleted(true);
      setUploadedFiles([...uploadedFiles, ...map(data.multiUpload, (file) => file.filename)]);
      setMyFiles([]);
    },
    onError: (e) => {
      console.log('error ', e)
    }
  });

  const [
    deleteFile
  ] = useMutation(DELETE_FILE)

  const removeFile = (file) => () => {

    return deleteFile({
      variables: {
        applicationId,
        uploadType,
        filename: file
      }
    }).then((data) => {
      setUploadedFiles(uploadedFiles.filter(f => f !== file))
    })
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections[0]?.size > maxSize ? setFileError(true) : setFileError(false)
    setCompleted(false);

    setMyFiles([...myFiles, ...acceptedFiles]);

    uploadFile({ variables: {
      files: acceptedFiles,
      applicationId,
      uploadType,
    },
    });
  }, []);

  const {
    // fileRejections,
    // acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
  });

  const downloadFile = async (filename) => {

    fetch(`${process.env.REACT_APP_FILE_DOWNLOAD_API}/${applicationId}/${uploadType}/${filename}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
   })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

  return (
    <Form.Group className={className} style={style || null}>
      <ErrorMessage name={name} component={ErrorLabel} />
      <Form.Label>{label}</Form.Label>
      <div
        {...getRootProps({
          className: 'dropzone text-center',
          disabled: false
        })}
        onClick={() => { open(); }}
      >
        <div className="upload-icon" />
        <input {...getInputProps({
            disabled: false
          })} />
        <p className="upload-text-box">
          <span className="upload-text">
            Click to upload
          </span>
          {' '}
          or drag and drop
        </p>
        <span className="form-accepted-files">{filesAccepted}</span>

      </div>
      {
        hint &&
        <div className="form-text form-hint hint-files">
         {hint}
        </div>
      }

      <aside>
        <div>
          {
            uploadedFiles.map((file, index) => {
              const uploaded = !isObject(file);
              return (
                <div
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className='file-div position-relative'
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="w-100">
                      <div className="d-flex w-100 justify-content-between file-div__padding">
                          <div className="w-100">
                            <p className="text-path d-flex font-weight-medium text-sm">{uploaded ? file : file.path}</p>
                            <div className="d-flex align-items-center mt-2">
                              <ProgressBar className="w-100" now={100} />
                              <div className="text-sm text-path ms-2">100%</div>
                            </div>
                            {!uploaded && (
                              <p className="text-size">
                                {(file.size / (1024 * 1024)).toFixed(2)}
                                {' '}
                                MB
                                {' '}
                              </p>
                            )}
                          </div>
                        {uploaded && <img src={Completed} height="16px" alt="completed" className="file-div__completed" />}
                      </div>
                      <div className="file-div__actions d-flex align-items-center justify-content-end">
                      <div
                        onClick={() => downloadFile(file)}
                        >
                          <Button
                            className="d-flex bg-white download-file-btn"
                            size="sm"
                            variant="light"
                          >
                            <div>
                              Download file
                              <svg className="ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </div>
                          </Button>
                        </div>
                        {!isAdmin && (
                          <Button
                            className="d-flex bg-white ms-2 delete-file-btn"
                            size="sm"
                            variant="light"
                            disabled={disabled}
                            onClick={removeFile(file)}
                          >
                            <div className={disabled ? 'delete-file-btn-disabled' : null}>
                              Delete file
                              <svg className="ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 4.99984H4.16667M4.16667 4.99984H17.5M4.16667 4.99984V16.6665C4.16667 17.1085 4.34226 17.5325 4.65482 17.845C4.96738 18.1576 5.39131 18.3332 5.83333 18.3332H14.1667C14.6087 18.3332 15.0326 18.1576 15.3452 17.845C15.6577 17.5325 15.8333 17.1085 15.8333 16.6665V4.99984H4.16667ZM6.66667 4.99984V3.33317C6.66667 2.89114 6.84226 2.46722 7.15482 2.15466C7.46738 1.8421 7.89131 1.6665 8.33333 1.6665H11.6667C12.1087 1.6665 12.5326 1.8421 12.8452 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </div>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {
              (uploadingError && !uploadingFile)
                ? (
                  <div />
                )
                : (
                  <>
                    {!uploaded && (
                      <div className="d-flex align-items-baseline file-div__padding">
                        <p className="text-percentage">{completed ? '100%' : '60%'}</p>
                      </div>
                    )}
                  </>
                )
            }
                </div>
              );
            })
          }
        </div>
        {
          fileError && 
          <div className='file-div-error file-div-error--sm position-relative'>
              <div className="d-flex w-100 justify-content-between pb-0">
                  <div className='w-100'>
                    <div className='d-flex align-items-baseline justify-content-between'>
                      <p className="d-flex text-path-error">Upload failed </p>
                      <IoClose className="close-btn" onClick={() => setFileError(false)} />
                    </div>
                      <p className="d-flex text-size-error pb-0 mb-0">
                      The size of the file you are attempting to upload exceeds the maximum permitted 
                      file size of {(maxSize / (1024*1024)).toFixed()}MB. <br/> 
                      Please upload a smaller file.
                      </p>
                  </div>
              </div>
            </div>
                        }
        <div>
          {
            myFiles.map((file, index) => {
              const uploaded = !isObject(file);
              return (
                <div
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${uploadingError ? 'file-div-error' : 'file-div'} position-relative`}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="w-100">
                      <div className="d-flex w-100 justify-content-between file-div__padding pb-0">
                        {(uploadingError && !uploadingFile) ? (
                          <div>
                            {/* <p className="d-flex text-path-error">{uploadingError}</p> */}
                            <p className="d-flex text-path-error">Upload failed, please try again</p>
                            <p className="d-flex text-size-error">
                              {file.path}
                            </p>
                            <p className="try-again">Try again</p>
                          </div>
                        ) : (
                          <div>
                            <p className="text-path d-flex text-sm font-weight-medium">{uploaded ? file : file.path}</p>
                            {/* <ProgressBar className="w-100 me-3" now={100} /> */}
                            {!uploaded && (
                              <p className="text-size text-sm">
                                {(file.size / (1024 * 1024)).toFixed(2)}
                                {' '}
                                MB
                                {' '}
                              </p>
                            )}
                          </div>
                        )}
                        {uploaded && <img src={Completed} alt="completed" className="file-div__completed" />}
                        {/* {uploadingError
                          ? <img onClick={removeFile(file)} src={ErrorDelete} alt="delete" className="delete-btn" />
                          : <img onClick={removeFile(file)} src={ImgDelete} alt="delete" className="delete-btn" />} */}
                      </div>
                    </div>
                  </div>
                  {
              (uploadingError && !uploadingFile)
                ? (
                  <div />
                )
                : (
                  <>
                    {!uploaded && (
                      <div className="d-flex align-items-baseline file-div__padding pt-0">
                        <ProgressBar className="text-sm me-3" now={completed ? 100 : 60} />
                        <p className="text-percentage">{completed ? '100%' : '60%'}</p>
                      </div>
                    )}
                  </>
                )
            }
                </div>
              );
            })
          }
        </div>
      </aside>
    </Form.Group>
  );
}

export default FormFiles;
