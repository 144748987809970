/* eslint-disable */
import React, { useContext, createContext, useState } from 'react';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN, ME } from '../queries';

export const AUTH_TOKEN = 'silta-user-token';
export const USERNAME = 'silta-user-name';

const authContext = createContext();

function useAuth() {
  return useContext(authContext);
}

function useAuthProvider() {
  const history = useHistory();
  const location = useLocation();
  const [loginError, setLoginError] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null)
  const client = useApolloClient();

  useQuery(ME,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data) {
          localStorage.setItem(USERNAME, data?.me?.username);
          setUser(data?.me)
          setEmail(data?.me?.email)
        }
      },
      onError: err => {
        logout()
      }
    },
  );

  const [login] = useMutation(LOGIN, {
    refetchQueries: [{ query: ME }],
    onCompleted: (data) => {
      const token = data.login.value;
      setUser(data.login.user)

      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(USERNAME, data.login.user.username);

      // consider setting custom headers to a me query request,
      // request user details, pass user details setter as props to login form
      history.push('/borrow/loan-applications');
    },
    onError: (err) => {
      // setLoginError(err);
      console.log('on error: ', err)
    },
    update: (store, response) => {
      if (response.errors && response.errors[0]) {
        const err = response.errors[0].message
        const capsOnFirstLetter = err[0].toUpperCase() + err.substring(1);
        setLoginError(capsOnFirstLetter)
      }
    },
  });


  const signin = (formState) => {
    setLoginError(null);

    return login({
      variables: { ...formState },
      refetchQueries: [{ query: ME }],
    });
  };

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USERNAME);
    client.clearStore();
    client.cache.gc();
    setUser(null)
    location.pathname !== '/impact-marketplace' && history.push('/impact-marketplace');
  };

  return {
    loginError,
    signin,
    logout,
    user,
    email,
    token: localStorage.getItem(AUTH_TOKEN),
    username: localStorage.getItem(USERNAME),
    isAdmin: user && user.role === 'admin'
  };
}

function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export { AuthProvider, useAuth };
