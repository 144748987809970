import ContactInformation from './components/Form/ContactInformation';
import CompanyInformation from './components/Form/CompanyInformation';
import IdentityVerification from './components/Form/IdentityVerification';
import ProjectInformation1 from './components/Form/ProjectInformation1';
import ProjectInformation2 from './components/Form/ProjectInformation2';
import ConfirmApplication from './components/Form/ConfirmApplication';

export const buyerApplicationFormConfig = {
  initialValues: {
    // Contact Information
    firstname: '',
    middlename: '',
    lastname: '',
    role: '',
    customrole: '',
    dateofbirth: '',
    phonenumber: '',
    email: '',
    linkedin: '',

    // Company information
    companyname: '',
    companytype: '',
    customcompanytype: '',
    companywebsite: '',
    companyid: '',
    companylogofiles: '',
    street: '',
    country: '',
    city: '',
    postalcode: '',
    usstate: '',
    companytaxnumber: '',
    missingtaxnumber: '',

    // Identity verification
    registrationDocument: '',
    shareholders: '',
    officerslist: '',
    authorizationletter: '',
    structurechart: '',
    groupstructure: '',
    sanctions: '',

    // Project information
    projectname: '',
    alternateprojectname: '',
    projectlocation: '',
    projectsector: '',
    projecttype: '',
    achieveimpact: '',
    sdggoals: [],
    projectimpactdescription: '',
    projectdrawdown: '',
    projectfinancingtype: '',
    projectdescription: '',
    projectdeveloperdescription: '',
    projectdeveloperreferences: '',
    projectreferencefiles: '',
    projecttotalcapital: '',
    projectloanamount: '',
    currency: '',
    projectloanperiod: '',
    projectinterestrate: '',
    projecttimetable: '',
    alternateprojecttimetable: '',
    projectConstructionStartDate: '',
    projectOperationsStartDate: '',
    projectOperationsLength: '',
    projectmodality: '',
    governmentsupport: '',
    governmentsupportdescription: '',
    projectirr: '',
    projectequitydebtratio: '',
    projectLeadershipLinkedIn: '',
    projectLeadershipTwitter: '',
    projectrelevantlinks: [],
  },

  steps: [
    {
      index: 0,
      route: '',
      title: 'Contact details',
      subtitle: 'Contact details for the company representative',
      description: 'Fill in the contact details of the company representative. This is the person who is authorised to represent the company and to submit the Pre-qualification application to Silta.',
      component: ContactInformation,
      inputs: [
        'firstname',
        'middlename',
        'lastname',
        'role',
        'customrole',
        'dateofbirth',
        'phonenumber',
        'email',
        'linkedin',
      ],
    },
    {
      index: 1,
      route: 'company',
      title: 'Company information',
      subtitle: 'Basic information about the company',
      description: 'Fill in basic information about the company applying for the loan.',
      component: CompanyInformation,
      inputs: [
        'companyname',
        'companytype',
        'customcompanytype',
        'companywebsite',
        'companyid',
        'companylogofiles',
        'street',
        'country',
        'city',
        'postalcode',
        'usstate',
        'companytaxnumber',
        'missingtaxnumber',
      ],
    },
    {
      index: 2,
      route: 'identity',
      title: 'Know your client (KYC) information',
      navTitle: 'Identity verification',
      subtitle: 'Verification of the company details and the credentials of the company representative',
      description:
        'This step is required in order to verify the company details and the credentials of the company representative. In later stages of the application process, additional information will be required.',
      component: IdentityVerification,
      inputs: [
        'registrationDocument',
        'shareholders',
        'officerslist',
        'authorizationletter',
        'structurechart',
        'groupstructure',
        'sanctions',
      ],
    },
    {
      index: 3,
      route: 'project',
      title: 'Project information (1/2)',
      navTitle: 'Project information',
      subtitle: 'Information about the project that the company is aiming to develop',
      description: 'Fill in information about the project that the company is aiming to develop.',
      component: ProjectInformation1,
      inputs: [
        'projectname',
        'alternateprojectname',
        'projectlocation',
        'projectsector',
        'projecttype',
        'achieveimpact',
        'sdggoals',
        'projectimpactdescription',
        'projectdrawdown:',
        'projectfinancingtype',
        'projectdescription',
        'projectdeveloperdescription',
        'projectdeveloperreferences',
        'projectreferencefiles',
      ],
    },
    {
      index: 4,
      route: 'project-2',
      title: 'Project information (2/2)',
      subtitle: 'Information about the project that the company is aiming to develop',
      description: 'Fill in information about the project that the company is aiming to develop.',
      component: ProjectInformation2,
      inputs: [
        'currency',
        'projecttotalcapital',
        'projectloanamount',
        'projectloanperiod',
        'projectinterestrate',
        'projecttimetable',
        'alternateprojecttimetable',
        'projectConstructionStartDate',
        'projectOperationsStartDate',
        'projectOperationsLength',
        'projectmodality',
        'governmentsupport',
        'governmentsupportdescription',
        'projectirr',
        'projectequitydebtratio',
        'projectLeadershipLinkedIn',
        'projectLeadershipTwitter',
        'projectrelevantlinks',
      ],
    },
    {
      index: 5,
      route: 'finish',
      title: 'Confirm and send application',
      subtitle: 'Review details',
      description: '',
      component: ConfirmApplication,
      inputs: [],
    },
  ],
};
