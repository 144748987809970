import React from 'react';
import { ErrorMessage } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';
import ErrorLabel from '../ErrorMessage';

function FormWeblink({ id, value, label, placeholder, name, onChange, onBlur, disabled, hint }) {
  return <Form.Group controlId={id}>
    <ErrorMessage name={name} component={ErrorLabel} />
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      <InputGroup.Prepend className={`${disabled && 'disabled'}`}>
        <InputGroup.Text
          className={disabled ? 'disabled' : ''}
          id="inputGroupPrepend"
        >
          https://

        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="text"
        placeholder={placeholder}
        aria-describedby="inputGroupPrepend"
        name={name}
        value={value || undefined}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled || ''}
        className="weblink-input"
      />
    </InputGroup>
    <div className="form-hint">{hint}</div>
  </Form.Group>
}

export default FormWeblink;
