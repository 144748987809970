import React from 'react';
import Layout from '../components/Layout';
import ProjectsContainer from '../components/ProjectsContainer';
import HomeBanner from '../components/HomeBanner';

function ImpactMarketplacePage() {
  return <Layout>
    <section className="project-list-page container">
      <HomeBanner
        title="Impact Marketplace"
        // subtitle="Discover impact projects seeking loans."
      />
      <ProjectsContainer />
    </section>
  </Layout>
}

export default ImpactMarketplacePage;
