/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
// import { useQuery } from '@apollo/client';
import { projListButtons, mockProject } from '../defaultData';
import Projects from './Projects';
import Pagination from './Pagination';
import ButtonNav from './ButtonNav';
// import SearchBarContainer from './SearchBarContainer';
// import Loading from './Loading';
// import { FETCH_ALL_APPLICATIONS } from '../queries';
import PrequalBanner from './PrequalBanner';

const selectionButtons = [
  { name: 'All projects', value: '' },
  { name: 'Pre-qualified', value: 'PREQUALIFIED' },
  { name: 'Scored by Silta', value: 'VOTING_OPEN' },
  { name: 'Financing', value: 'FINANCING' },
  { name: 'Funded', value: 'PROJECT_FUNDED' },
];

function ProjectsContainer() {
  const [selected, setSelected] = useState({ name: 'All projects', value: '' });
  const [projectsFetched, setProjectsFetched] = useState(mockProject);
  const [projectsFiltered, setProjectsFiltered] = useState(mockProject);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [valueInput, setValueInput] = useState();

  // const { loading, error } = useQuery(FETCH_ALL_APPLICATIONS, {
  //   fetchPolicy: 'cache-and-network',
  //   onCompleted: ({ allApplications }) => {
  //     setProjectsFetched(allApplications);
  //     setProjectsFiltered(allApplications);
  //   },
  // });

  // const ready = !(loading || error);
  const projPerPage = 10;
  const startIndex = (currentPage - 1) * projPerPage;
  const projPage = projectsFiltered.slice(startIndex, startIndex + projPerPage);

  useEffect(() => {
    const tp = Math.ceil(projectsFiltered.length / projPerPage);
    setTotalPages(tp > 0 < 1 ? 1 : tp);
  }, [projectsFiltered]);

  useEffect(() => {
  }, [projectsFetched, projectsFiltered, selected]);

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClick = (name) => {
    const searchButton = selectionButtons.find((btn) => btn.name === name);
    setSelected(searchButton);

    if (name === 'All projects') {
      setProjectsFiltered(projectsFetched);
    } else if (name === 'Pre-qualified') {
      const filtered = projectsFetched.filter((proj) => proj.state === 'PREQUALIFIED');
      setProjectsFiltered(filtered);
    } else if (name === 'Scored by Silta') {
      const filtered = projectsFetched.filter((proj) => proj.state === 'VOTING_OPEN');
      setProjectsFiltered(filtered);
    } else if (name === 'Financing') {
      const filtered = projectsFetched.filter((proj) => proj.state === 'FINANCING');
      setProjectsFiltered(filtered);
    } else if (name === 'Funded') {
      const filtered = projectsFetched.filter((proj) => proj.state === 'PROJECT_FUNDED');
      setProjectsFiltered(filtered);
    }
    setValueInput('');
  };

  // const handleSearch = (e) => {
  //   const filtered = projectsFetched.filter(
  //     (proj) => proj.projectname.toLowerCase().includes(e.toLowerCase())
  //     && (proj.state === selected.value || selected.name === 'All projects'),
  //   );
  //   setProjectsFiltered(filtered);
  //   setValueInput(e);
  // };
  // console.log(handleSearch);

  return (
    <div>
      <h1 className="section_title">Projects</h1>
      {/* <p className="section_subtitle">List of projects seeking finance</p> */}
      <ButtonNav
        handleClick={handleClick}
        buttons={projListButtons}
        selected={selected.name}
        tooltip
      />
      {/* <SearchBarContainer handleSearch={handleSearch} valueInput={valueInput} /> */}
      {/* {
        !ready
          ? <Loading />
          :
      } */}
      <Projects projects={projPage} />
      <Pagination
        currentPage={currentPage}
        previousPage={previousPage}
        nextPage={nextPage}
        totalPages={totalPages}
      />
      <PrequalBanner />
    </div>
  );
}

export default ProjectsContainer;
