/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { BsPlusLg, BsXLg } from 'react-icons/bs';
import { Formik } from 'formik';
import * as yup from 'yup';
import ButtonNav from './ButtonNav';
import { FormText } from './Form';

const validationSchema = yup.object({
  name: yup
    .string('Application name')
    .required('Name is required')
    .min(4, 'Name should be at least 4 characters'),
});

function NewApplicationsContainer({ createApplication }) {
  const [namingModal, setNamingModal] = useState(false);

  return (
    <div>
      <ButtonNav
        buttons={[{ name: 'New application' }]}
        selected="New application"
      />

      <div className="loan-applications mt-5" style={{ marginBottom: 62 }}>
        <div
          className="application application--new d-flex flex-column justify-content-center"
          onClick={() => setNamingModal(true)}
        >
          <div className="application__title text-center">
            New loan
            <br />
            {' '}
            application
          </div>
          <Button
            variant="primary"
            size="lg"
            className="application__btn text-nowrap d-flex align-items-center"
          >
            <BsPlusLg />
          </Button>
        </div>
      </div>
      <Modal
        show={namingModal}
        className="application-modal"
      >
        <Modal.Body className=" p-0">
          <Formik
            initialValues={{ name: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => createApplication({ variables: { applicationname: values.name } })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-lg">New loan application</div>
                  <Button
                    variant="link"
                    className="close-modal-btn"
                    onClick={() => setNamingModal(false)}
                  >
                    <BsXLg />
                  </Button>
                </div>
                <div className="text-sm text-gray mb-4">Enter the name for this application</div>
                <FormText
                  type="text"
                  name="name"
                  placeholder="e.g. Loan Application for 15M"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className={touched.name && errors.name ? 'error' : null}
                  label="Application name"
                  margin
                />
                <Button
                  type="submit"
                  color="primary"
                  className="w-100 mt-1 mb-2 pt-2 pb-2 confirm-application"
                  disabled={!values.name.length || errors.name}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NewApplicationsContainer;
