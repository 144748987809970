/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Web3ReactProvider } from '@web3-react/core';
import { map } from 'lodash-es';

import { AuthProvider } from './providers/AuthProvider';
import MetamaskProvider from './providers/MetamaskProvider';
import ApplicationFormProvider from './providers/ApplicationForm';
import ScrollToTop from './providers/ScrollToTop';
import DisclaimerProvider from './providers/DisclaimerProvider';

import client from './client';
import getLibrary from './library';
import { buyerApplicationFormConfig } from './formConfig';

import ImpactMarketplacePage from './pages/ImpactMarketplace';
import ProjectPage from './pages/Project';  
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import SignUp from './pages/SignUp';
import Reset from './pages/Reset';
import LoanApplications from './pages/LoanApplications';
import ApplicationForm from './pages/ApplicationForm';
import AdminApplications from './pages/AdminApplications';
import PrivateRoute from './components/PrivateRoute';

import AccActivate from './components/AccActivate';

import './styles/styles.scss';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <ScrollToTop>
      <AuthProvider>
        {/* <Web3ReactProvider getLibrary={getLibrary}>
          <MetamaskProvider> */}
            <Switch>
              <Route path="/" exact>
                <Redirect to="/impact-marketplace" />
              </Route>
              <Route path="/impact-marketplace" exact>
                <ImpactMarketplacePage />
              </Route>
              <Route path="/impact-marketplace/project/:id" exact>
                <ProjectPage />
              </Route>
              <Route path="/activate-account/:id" exact>
                <AccActivate />
              </Route>
            {/* </Switch> */}
          {/* </MetamaskProvider>
        </Web3ReactProvider> */}
          {/* <Switch> */}
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/sign-up" exact>
              <SignUp />
            </Route>

            <Route path="/forgot-password" exact>
              <Forgot />
            </Route>

            <Route path="/password-reset/:token" exact>
              <Reset />
            </Route>

            <PrivateRoute path="/borrow/loan-applications" exact>
              <LoanApplications />
            </PrivateRoute>

            <PrivateRoute path="/borrow/admin" exact>
              <AdminApplications />
            </PrivateRoute>
            <DisclaimerProvider>
            <ApplicationFormProvider>
              {setCommentModalShown => (
                <>
                  {map(buyerApplicationFormConfig.steps, (step) => (
                    <PrivateRoute
                      key={step.route}
                      exact
                      path={`/borrow/application/:id?/${step.route}`}
                    >
                      <ApplicationForm
                        {...step}
                        setCommentModalShown={setCommentModalShown}
                      />
                    </PrivateRoute>
                  ))}
                </>
              )}
            </ApplicationFormProvider>
            </DisclaimerProvider>
          </Switch>
        </AuthProvider>
      </ScrollToTop>
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);
