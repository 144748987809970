import { ErrorMessage } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import ErrorLabel from '../ErrorMessage';

function FormSelect({
  value,
  name,
  label,
  options,
  disabled,
  onBlur,
  onChange,
  hint,
  placeholder,
  selectCountry,
}) {
  const defaultValue = (o, v) => (o ? o.find((option) => option.value === v) : options[0]);

  return (
    <Form.Group>
      <ErrorMessage name={name} component={ErrorLabel} />
      <Form.Label>{label}</Form.Label>
      <Select
        label={label}
        classNamePrefix="selectStyle"
        value={defaultValue(options, value)}
        options={options}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled || null}
        isSearchable={selectCountry || false}
      />
      <div className="form-hint">{hint}</div>
    </Form.Group>
  );
}

export default FormSelect;
