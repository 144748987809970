import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useFormikContext, ErrorMessage } from 'formik';
import { countries } from 'country-data';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import ErrorLabel from '../ErrorMessage';


const fullOptions = countries.all.map((country) => {
  const { countryCallingCodes, name, alpha2, alpha3 } = country

  if(countryCallingCodes.length === 0 || name === 'European Union' || name === 'France, Metropolitan'){
    return false
  }

  return { 'label': alpha3 || alpha2, 'value': name, 'prefix': countryCallingCodes[0] }
})
const options = fullOptions.filter((filt) => filt !== false )


function FormPhone(
  { id, value, label, disabled, placeholder, name, onChange, onBlur, hint },
) {
  const [optionSelected, setOptionSelected] = useState(options.find((opt) => opt.label === "ESP"));
  const { setFieldValue } = useFormikContext();

  const handlePrefixChange = (e) => {
    const selected = options.find((elem) => elem.value === e.value);
    setOptionSelected(selected);
    setFieldValue(name, e.prefix);
  };
  useEffect(() => {
    setFieldValue(name, optionSelected?.prefix);
  }, [])

  return (
    <Form.Group controlId={id}>
      <ErrorMessage name={name} component={ErrorLabel} />
      <Form.Label>{label}</Form.Label>
      <div className={`container-group ${disabled && 'disabled'}`}>
        <InputGroup className="input-phone">
          <Select
            classNamePrefix="selectPhone"
            options={options}
            defaultValue={optionSelected}
            onChange={(e) => handlePrefixChange(e)}
            isDisabled={disabled}
          />
          {/* <InputGroup.Prepend className="prepend-phone">
            <InputGroup.Text
              className={disabled ? 'disabled' : ''}
              id="inputGroupPrepend"
            >
              {/* {optionSelected.prefix}

            </InputGroup.Text>
          </InputGroup.Prepend> */}
          <Form.Control
            type="text"
            placeholder={placeholder}
            aria-describedby="inputGroupPrepend"
            name={name}
            value={value || undefined}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled || ''}
          />
        </InputGroup>
      </div>
      <div className="form-hint">{hint}</div>
    </Form.Group>
  );
}

export default FormPhone;
