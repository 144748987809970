import React from 'react';
import { Spinner } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
export default function BorrowerLoading({ error, padded = false }) {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ paddingRight: !padded ? '0' : '117px' }}>
      <Spinner animation="grow" variant="primary" />
    </div>
  );
}
