/* eslint-disable max-len */
import React from 'react';

function ConfirmApplication() {
  return <div
    className="text-gray confirm-application mt-2 mb-5"
  >
    By clicking &quot;Submit application&quot; below, you confirm that:
    <br />
    (i) you are authorized by your company to complete and submit this application;
    <br />
    (ii) all information submitted herein is accurate and complete to the best of your knowledge;
    <br />
    (iii) you agree to Silta
    {' '}
    <a href="https://silta.finance/terms-of-use/" target="_blank" rel="noreferrer">terms of use</a>
    {' '}
    and to Silta
    {' '}
    <a href="https://silta.finance/privacy-policy/" target="_blank" rel="noreferrer">privacy policy</a>
    ; and
    <br />
    (iv) you confirm that you are familiar with and adhere to Silta
    {' '}
    <a href="https://silta.finance/terms-of-use#competitions" target="_blank" rel="noreferrer">sanctions policy.</a>
  </div>
}

export default ConfirmApplication;
