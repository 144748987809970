import React from 'react';
import { ErrorMessage } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import Select from 'react-select';
import ErrorLabel from '../ErrorMessage';

function FormPercentage({ id, value, label, disabled, placeholder, name,
  // eslint-disable-next-line no-unused-vars
  onChange, onBlur, hint, wideLabel }) {
  return <Form.Group controlId={id} className="currency-input">
      <ErrorMessage name={name} component={ErrorLabel} />
      <Form.Label className={wideLabel && 'wide-label'}>{label}</Form.Label>
      <div className={`container-group ${disabled && 'disabled'}`}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text
              className={disabled ? 'disabled' : ''}
              id="inputGroupPrepend"
            >
              %
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            placeholder={placeholder}
            aria-describedby="inputGroupPrepend"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled || ''}
          />
        </InputGroup>
      </div>
      <div className="form-hint">{hint}</div>
    </Form.Group>
}

export default FormPercentage;
