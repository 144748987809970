import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelpIcon from '../assets/images/icons/help.svg';

function ButtonNav({ handleClick, buttons, selected, tooltip, tooltipTitle }) {
  return <div className="container button-nav">
    {
        buttons.map((btn) => (
          <div key={btn.name} className="button-nav__container">
            <button
              key={btn.name}
              type="button"
              onClick={() => handleClick(btn.name)}
              className={selected === btn.name ? 'active' : null}
              disabled={btn.state === 'disabled'}
            >
              {btn.name}
            </button>
            {
              tooltip
            && (
            <span>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id={btn.name} style={{ opacity: 1 }}>
                    {
                     tooltipTitle
                     && <p className="btn-tooltip-name">{btn.name}</p>
                   }
                    <p className="btn-tooltip-description">{btn.description}</p>
                  </Tooltip>
    )}
              >
                <img src={HelpIcon} alt="Help icon" className="tooltip-help" />
              </OverlayTrigger>
            </span>
            )
            }
          </div>
        ))
   }
  </div>
}

export default ButtonNav;
