import React from 'react';
import arrowLeft from '../assets/images/arrow-left.svg';
import arrowRight from '../assets/images/arrow-right.svg';

function Pagination({ currentPage, previousPage, nextPage, totalPages }) {
  return <div className="pagination-container d-flex justify-content-center align-items-center">
    <button
      className="pagination__btn"
      type="button"
      disabled={currentPage === 1}
      onClick={() => previousPage()}
    >
      <img src={arrowLeft} alt="previous" />
    </button>
    <p className={`pagination__text ${currentPage === totalPages && 'pagination__text-disabled'}`}>
      Page
      {' '}
      {currentPage}
      {' '}
      of
      {' '}
      {totalPages}
    </p>
    <button
      className="pagination__btn"
      type="button"
      disabled={currentPage === totalPages}
      onClick={() => nextPage()}
    >
      <img src={arrowRight} alt="next" />
    </button>
  </div>
}

export default Pagination;
