/* eslint-disable max-len */
import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';

export default function Footer({ className }) {
  return (
    <footer className={`footer ${className}`}>
      <Link to="/" className="footer__logo d-flex justify-content-center">
        <img src={logo} alt="Logo" />
      </Link>
      <div className="footer__socials">
        <div className="container">
          <div className="d-flex justify-content-center">
            <a
              className="footer__socials__link"
              href="https://twitter.com/silta_fi"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="footer__socials__link"
              href="https://www.linkedin.com/company/silta-finance/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="footer__socials__link"
              href="https://medium.com/silta-finance"
              target="_blank"
              rel="noreferrer"
            >
              Medium
            </a>
            <a
              className="footer__socials__link"
              href="https://www.youtube.com/channel/UCefrSCL16p_NKbYXJfKMHWQ"
              target="_blank"
              rel="noreferrer"
            >
              Youtube
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer__policy d-flex justify-content-between align-items-start">
          <div>
            <div>© {new Date().getFullYear()} Silta Finance. All rights reserved.</div>
            <div className="mt-4">
              <p>
                This content is not intended as any kind of investment advice.
                <br />
                Use of this site is subject to our
                <a href="https://silta.finance/terms-of-use/" target="_blank" rel="noreferrer" className="mx-1 footer__underlined-text">
                  terms of use
                </a>
                and the general disclaimer set forth therein.
              </p>

              <a href="https://silta.finance/" target="_blank" rel="noreferrer" className="mx-0 d-flex align-items-center">
                Silta finance website <FiArrowUpRight className='footer__arrow-right-icon' />
                </a>
            </div>
          </div>
          <div className="d-flex">
            <a href="https://silta.finance/terms-of-use/" target="_blank" rel="noreferrer">
              Terms
            </a>
            <a href="https://silta.finance/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy
            </a>
            <a href="/privacy-policy#cookies" target="_blank" rel="noreferrer">
              Cookies
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
