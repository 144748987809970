/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { map } from 'lodash-es';
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { FormText, FormCheck, FormFiles } from './index';
import ErrorLabel from '../ErrorMessage';
/**
  // Project information
    projectname: '',
    alternateprojectname: '',
    projectlocation: '',
    projectsector: '',
    achieveimpact: '',
    sdggoals: [],
    projectimpactdescription: '',
    projectdrawdown: '',
    projectfinancingtype: '',
    projectdescription: '',
    projectdeveloperdescription: '',
    projectdeveloperreferences: '',
    projectdeveloperReferencesFiles: '',
    projecttotalcapital: '',
    projectloanamount: '',
    projectloanperiod: '',
    projectinterestrate: '',
    projecttimetable: '',
    alternateprojecttimetable: '',
    projectConstructionStartDate: '',
    projectOperationsStartDate: '',
    projectOperationsLength: '',
    projectmodality: '',
    governmentsupport: '',
    governmentsupportdescription: '',
    projectirr: '',
    projectequitydebtratio: '',
    projectLeadershipLinkedIn: '',
    projectLeadershipTwitter: '',
    projectrelevantlinks: [],
*/

const achieveImpact = ['Yes', 'No'];
const SDGGoals = [
  'No poverty',
  'Reduced inequality',
  'Zero hunger',
  'Sustainable cities and communities',
  'Good health and well-being',
  'Responsible consumption and production',
  'Quality education',
  'Climate action',
  'Gender equality',
  'Life below water',
  'Clean water and sanitation',
  'Life on land',
  'Affordable and clean energy',
  'Peace, justice and strong institutions',
  'Decent work and economic growth',
  'Partnerships for the goals',
  'Industry, innovation and infrastructure',
  'Other'];

const tooltipLoanType = { title: 'Loan type', text: 'Define the seniority and the type of loan requested. Loan seniority defines the order of repayment in case of default. Senior debt must be repaid before subordinated (mezzanine or junior) debt is repaid. Elaborate if necessary.' };
const tooltipProject = { title: 'About the project', text: 'Describe the physical infrastructure asset to be developed in the project. Be specific about the asset, the location, and the funding required (both equity and debt).' };
const tooltipDeveloper = { title: 'About the developer', text: 'Describe the developer, its background, credentials, and track record in infrastructure development (including ESG conscious prior projects, if applicable).' };
const tooltipDeveloperProjects = { title: 'Developer reference projects', text: 'A list of relevant prior projects by the developer. These can be projects already completed, in construction, or in operation. Be specific about the sector, asset, type and size of funding, location, and dates.' };

// eslint-disable-next-line no-unused-vars
function ProjectInformation1({ applicationStateDisabled }) {
  const { values, setFieldValue } = useFormikContext();

  const handleCheckboxes = (field, e) => {
    const isChecked = values.sdggoals.find((val) => val === e.target.value);

    // eslint-disable-next-line no-unused-expressions
    isChecked === undefined ? setFieldValue(field.name, [...values.sdggoals, e.target.value])
      : setFieldValue(field.name, values.sdggoals.filter((i) => i !== e.target.value));
  };

  useEffect(() => {
  }, [values.sdggoals]);

  return (
    <div>
      <div className="d-flex w-100 justify-content-between">
        <Field name="projectname">
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert project title"
              className={meta.touched && meta.error ? 'error' : null}
              label="Project title"
            />
          )}
        </Field>
        <Field name="alternateprojectname">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert another name"
              className={meta.touched && meta.error ? 'error' : null}
              label="Is the project known by other name?"
              hint="Optional"
              nomargin
            />
          )}
        </Field>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <Field name="projectlocation">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert location"
              className={meta.touched && meta.error ? 'error' : null}
              label="Project location (city, country)"
            />
          )}
        </Field>
      </div>
      <div>
        <Field name="projectsector">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              className={meta.touched && meta.error ? 'error' : null}
              label="Sector"
              placeholder="Insert sector"
              hint="E.g. renewables, utilities, social services, transportation, waste management, etc."
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 90 }}>
        <Field name="projecttype">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              className={meta.touched && meta.error ? 'error' : null}
              label="Type"
              placeholder="Insert type of project"
              hint="E.g, solar farm, clean water project, research center, eco products producer, waste to energy plant"
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 95 }}>
        <Field name="achieveimpact">
          {({
            field,
            meta,
          }) => (
            <>
              <label className="form-label w-50 mb-2">
                Does your project achieve measurable social and/or environmental impact?
              </label>
              {map(achieveImpact, (type, index) => (
                <FormCheck
                  key={index}
                  id={type + index}
                  {...field}
                  type="radio"
                  className={`${meta.touched && meta.error ? 'error-radio' : null} mb-2`}
                  label={type}
                  classNameCheck="no-bg"
                />
              ))}
            </>
          )}
        </Field>
      </div>

      <div style={{ marginTop: 55 }}>
        <label className="form-label mb-2">
          Which sustainable development goals (SDG) does your project contribute towards?
        </label>
        <p className="subtl mb-4">
          Select all the goals to which your project contributes from the list below.
        </p>
        <ErrorMessage name="sdggoals" component={ErrorLabel} className="checkbox-error" />
        <div className="d-flex flex-wrap" style={{ marginRight: 0 }}>
          { map(SDGGoals, (type, index) => (
            <Field
              name="sdggoals"
              type="checkbox"
              value={type}
            >
              {({
                field,
                meta,
              }) => (
                <FormCheck
                    key={index}
                    id={type + index}
                    {...field}
                    onChange={(e) => handleCheckboxes(field, e)}
                    type="checkbox"
                    className={`${meta.touched && meta.error ? 'error-checkbox' : null} me-5`}
                    label={type}
                    checked={values.sdggoals.find((i) => i === type)}
                    classNameCheck="no-bg"
                  />
              )}
            </Field>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 30, marginBottom: 80 }}>
        <Field name="projectimpactdescription">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Enter a description..."
              className={meta.touched && meta.error ? 'error' : null}
              textarea
              maxLength="1000"
              label="Describe the social and environmental impacts of your project and how you will measure them"
              hint="Max 1000 characters"
            />
          )}
        </Field>
      </div>

      <div className="form-separator" />

      <div style={{ marginTop: 50 }}>
        <Field name="projectdrawdown">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert date"
              className={meta.touched && meta.error ? 'error' : null}
              label="When do you seek the first drawdown of the loan?"
              hint="dd/mm/yyyy"
              wideLabel
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 60, marginBottom: 80 }}>
        <Field name="projectfinancingtype">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              textarea
              placeholder="Enter a description..."
              className={meta.touched && meta.error ? 'error' : null}
              label="Loan type"
              hint="E.g. Senior debt, Junior debt"
              onChange={(e) => setFieldValue(field.name, e.target.value)}
              tooltip={tooltipLoanType}
            />
          )}
        </Field>
      </div>

      <div className="mt-5">
        <Field name="projectdescription">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Enter a description..."
              className={meta.touched && meta.error ? 'error' : null}
              textarea
              label="About the project"
              maxLength="3000"
              hint="Max 3000 characters"
              tooltip={tooltipProject}
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 70 }}>
        <Field name="projectdeveloperdescription">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Enter a description..."
              className={meta.touched && meta.error ? 'error' : null}
              textarea
              label="About the developer"
              maxLength="3000"
              hint="Max 3000 characters"
              tooltip={tooltipDeveloper}
            />
          )}
        </Field>
      </div>

      <div className="d-flex justify-content-between w-100" style={{ marginTop: 70 }}>
        <div className="form-group">
          <Field name="projectdeveloperreferences">
            {({
              field,
              meta,
            }) => (
              <FormText
                {...field}
                placeholder="Insert text"
                className={meta.touched && meta.error ? 'error' : null}
                textarea
                label="Developer reference projects"
                maxLength="250"
                hint="List relevant reference project names and links, if available. Max 250 characters"
                tooltip={tooltipDeveloperProjects}
              />
            )}
          </Field>
        </div>
        {/* <div className="w-50 ms-5"> */}
        <div className="form-group w-50 me-0">
          <Field name="projectreferencefiles">
            {({
              field,
              meta,
            }) => (
              <div className="position-relative">
                <div className="form-label mb-0">
                  Additional information about reference projects
                </div>
                <FormFiles
                  {...field}
                  filesAccepted="PDF, PNG, JPG up to 50MB"
                  accept="image/jpeg, image/png, .pdf"
                  maxSize={52428800}
                  className={`${meta.touched && meta.error ? 'error' : null} mb-1`}
                  label=""
                  uploadType="DEVELOPER_FILES"
                  hint="Upload documents that we may share with potential lenders to help them better understand your project."
                  style={{ maxWidth: '100%' }}
                  disabled={applicationStateDisabled}
                />
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default ProjectInformation1;
