import React from 'react';
import { map, findIndex, every } from 'lodash-es';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { buyerApplicationFormConfig } from '../../formConfig';

export default function SideNav() {
  // TODO: get from route
  const { id } = useParams();
  const { location: { pathname } } = useHistory();
  const formikContext = useFormikContext()
  
  // eslint-disable-next-line max-len
  let activeIndex = findIndex(buyerApplicationFormConfig.steps, (step) => (step.route !== '' && pathname.includes(step.route))) || 0;
  if (activeIndex === -1) activeIndex = 0;
  
  let isError = [];
  isError = buyerApplicationFormConfig.steps.map(((step) => every(
    step.inputs,
    // eslint-disable-next-line max-len
    (inputName) => !(formikContext.getFieldMeta(inputName).touched && formikContext.getFieldMeta(inputName).error),
  )
  ));

  return (
    <aside className="side-nav side-nav--form">
      <div>
        {map(buyerApplicationFormConfig.steps, (route, index) => (
          index !== 4 && (
            <Link
              key={index}
              to={`/borrow/application/${id}/${
                buyerApplicationFormConfig.steps[index].route
              }`}
              className="side-nav--form__link"
            >
              <div
                className={`
            side-nav--form__item d-flex
            ${(index === activeIndex && isError[index]) && 'side-nav--form__item--active'}
            ${(!isError[index]) && 'side-nav--form__item--error'}
          `}
              >
                <div className={`
              side-nav--form__item__indicator
              ${(index + 1 === buyerApplicationFormConfig.steps.length) && 'side-nav--form__item__indicator--last'}
              ${(index === activeIndex && isError[index]) && 'side-nav--form__item__indicator--active'}
              ${(index < activeIndex && isError[index]) && 'side-nav--form__item__indicator--completed'}
              ${(!isError[index]) && 'side-nav--form__item__indicator--error'}
            `}
                />
                <div>
                  <div className="side-nav--form__item__title">{route.navTitle || route.title}</div>
                  <div className="side-nav--form__item__subtitle">
                    {route.subtitle}
                  </div>
                  {/* {
                  (!isError[index] && index !== activeIndex)
                  && <div className="side-nav--form__item__review">
                  Review the information</div>
                  } */}
                </div>
              </div>
            </Link>
          )
        ))}
      </div>
    </aside>
  );
}
