import { ErrorMessage, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import Select from 'react-select';
import ErrorLabel from '../ErrorMessage';

const options = [
  { label: 'USD', value: 'usd', symbol: '$' },
  { label: 'EUR', value: 'eur', symbol: '€' },
  { label: 'GBP', value: 'gbp', symbol: '£' },
  { label: 'SGD', value: 'sgd', symbol: 'S$' },
];

function FormCurrency(
  { id, value, label, disabled, placeholder, name,
    // eslint-disable-next-line no-unused-vars
    onChange, onBlur, hint },
) {
  // eslint-disable-next-line no-unused-vars
  const [optionSelected, setOptionSelected] = useState(options[0]);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    const selected = options.find((elem) => elem.value === e.value);
    setOptionSelected(selected);
    setFieldValue('currency', e.value);
  };

  return (
    <Form.Group controlId={id} className="currency-input">
      <ErrorMessage name={name} component={ErrorLabel} />
      <Form.Label>{label}</Form.Label>
      <div className={`container-group ${disabled && 'disabled'}`}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text
              className={disabled ? 'disabled' : ''}
              id="inputGroupPrepend"
            >
              {optionSelected.symbol}

            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            placeholder={placeholder}
            aria-describedby="inputGroupPrepend"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled || ''}
          />
          <Select
            classNamePrefix="selectCurency"
            options={options}
            defaultValue={optionSelected}
            onChange={(e) => handleChange(e)}
            isDisabled={disabled}
            isSearchable={false}
          />
        </InputGroup>
      </div>
      <div className="form-hint">{hint}</div>
    </Form.Group>
  );
}

export default FormCurrency;
