/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { every } from 'lodash-es';
import { Button, Form, Modal } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight, BsArrowRight, BsCheck2Circle, BsXLg } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useAuth } from '../../providers/AuthProvider';
import { buyerApplicationFormConfig } from '../../formConfig';
import logo from '../../assets/images/logo.svg';
import Footer from '../Footer';
// eslint-disable-next-line import/no-named-as-default
import SideNav from './SideNav';
import Notification from '../Notification';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FETCH_LOAN_APPLICATION, EDIT_LOAN_APPLICATION } from '../../queries';
import EditIcon from '../../assets/images/icons/edit.svg';
import FormText from './FormText';
import { useDisclaimerContext } from '../../providers/DisclaimerProvider';

export default function ApplicationFormLayout({
  children,
  routeIndex,
  applicationStateDisabled,
  setCommentModalShown,
  loading,
}) {
  const [submitModalShown, setSubmitModalShown] = useState(false);
  const [error, setError] = useState(false);
  const [updateForm] = useMutation(EDIT_LOAN_APPLICATION);
  const [applicationnname, setApplicationnname] = useState('');
  const [applicationstate, setApplicationstate] = useState('');
  const [editModalShown, setEditModalShown] = useState(false);
  const [newName, setNewName] = useState('');
  const { id } = useParams();
  const { isAdmin } = useAuth();
  const { handleSubmit, isValid } = useFormikContext();
  const formikContext = useFormikContext();
  const disclaimers = useDisclaimerContext();
  const { openDisclaimer, handleClose } = disclaimers;

  const formStepIsValid = buyerApplicationFormConfig.steps[routeIndex] && every(
    buyerApplicationFormConfig.steps[routeIndex].inputs,
    (inputName) => !(formikContext.getFieldMeta(inputName).touched && formikContext.getFieldMeta(inputName).error),
  );

  useEffect(() => {
  }, [applicationnname]);

  useEffect(() => {
  }, [applicationstate]);

  const updateApplicationName = () => {
    setApplicationnname(newName);
    updateForm({
      variables: {
        applicationId: id,
        applicationname: newName,
      },
    });
    setEditModalShown(false);
  };

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(FETCH_LOAN_APPLICATION, {
    variables: { applicationId: id },
    policy: 'cache-and-network',
    onCompleted: ({ myApplication }) => {
      setApplicationnname(myApplication.applicationname);
      setNewName(myApplication.applicationname);
      setApplicationstate(myApplication.state);
    },
  });

  const handleValid = () => {
    setSubmitModalShown(true);
    setError(false);
  };

  return (
    <>
      <div className="silta">
        <nav className="nav">
          <div className="container d-flex align-items-center justify-content-center" style={{ paddingTop: '27px' }}>
            { openDisclaimer && (
            <div className="nav__disclaimer d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <span className="nav__disclaimer__title me-2">Confidentiality disclaimer </span>
                <span className="nav__disclaimer__subtitle me-2">The information you provide will not be shared with anyone other than Silta. </span>
                <span className="nav__disclaimer__link"><a href="https://silta.finance/privacy-policy/" target="_blank" rel="noreferrer">View privacy policy</a></span>
              </div>
              <div className="nav__disclaimer__close" role="button" onClick={handleClose}>
                <CgClose />
              </div>
            </div>
            )}
            <Link to="/impact-marketplace" className="nav__logo">
              <img src={logo} alt="Silta home" />
            </Link>
          </div>
        </nav>
        <div className="container-fluid position-relative d-flex ps-0 pe-0">
          <SideNav />
          <div className="silta-page silta-page--with-sidebar silta-page--with-sidebar--form">
            <div className="silta-form__breadcrumbs">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Link to={isAdmin ? '/borrow/admin' : '/borrow/loan-applications'}>
                    {isAdmin ? 'Admin panel' : 'Borrower portal'}
                  </Link>
                  <span className="ms-3 me-3 breadcrumb-slash">/</span>
                  <span className="breadcrumb-name">
                    {applicationnname}
                    {' '}
                    {
                      applicationstate === 'DRAFT' && <span className="edit-name-btn" onClick={() => setEditModalShown(true)}><img src={EditIcon} alt="Edit icon" /></span>
                    }
                  </span>
                </div>

                <Modal
                  show={editModalShown}
                  onHide={() => setEditModalShown(false)}
                  className="application-modal"
                >
                  <Modal.Body className="p-0">
                    <Formik>
                      {({
                        errors,
                        touched,
                        handleBlur,
                      }) => (
                        <Form>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="text-lg">Change application name</div>
                            <Button
                              variant="link"
                              className="close-modal-btn"
                              onClick={() => setEditModalShown(false)}
                            >
                              <BsXLg />
                            </Button>
                          </div>
                          <div className="text-sm text-gray mb-4 text-left">Enter the name for this application</div>
                          <FormText
                            type="text"
                            name="applicationname"
                            placeholder=""
                            onChange={(e) => setNewName(e.target.value)}
                            onBlur={handleBlur}
                            value={newName || ''}
                            className={`${touched.name && errors.name ? 'error' : null} text-left mb-0`}
                            label="Application name"
                            margin
                          />
                          <Button
                            color="primary"
                            className="w-100 mt-1 mb-2 pt-2 pb-2 confirm-application"
                            onClick={updateApplicationName}
                            disabled={!newName.length || errors.name}
                          >
                            Confirm
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>

                {!(isAdmin) ? (
                  <Link to="/borrow/loan-applications">
                    <Button variant="outline-primary" className="btn-borrower">
                      Save and exit
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="outline-primary"
                    onClick={() => setCommentModalShown(true)}
                  >
                    Update status
                  </Button>
                )}
              </div>
            </div>
            <div className={`silta-form d-flex flex-column justify-content-between ${applicationStateDisabled && 'silta-form--disabled'}`}>
              {/* {!isValid && routeIndex + 1 === buyerApplicationFormConfig.steps.length && */}
              {(routeIndex + 1 === buyerApplicationFormConfig.steps.length) && error && (
                <div className="mb-3">
                  <Notification
                    title="There are errors in the application form."
                    text="Please check fields marked in red on the left sidebar menu before submitting the application."
                  />
                </div>
              )}
              {!formStepIsValid && (
                <div className="mb-3">
                  <Notification
                    title="There are errors in the application form."
                    text="Please check fields marked in red before continuing."
                  />
                </div>
              )}
              <div>
                <div className="silta-form__section">
                  {loading ? (
                    <div className="silta-form__skeleton">
                      <div className="silta-form__skeleton__item" style={{ width: '50%' }} />
                      <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '25%' }} />
                    </div>
                  ) : (
                    <h1>Pre-qualification application</h1>
                  )}
                </div>
                {children}
              </div>
              <div className="silta-form__pagination">
                <div className={routeIndex + 1 === buyerApplicationFormConfig.steps.length ? 'no-border' : 'silta-form__section'} />
                <div className="d-flex align-center justify-content-between">
                  {routeIndex ? (
                    <Link
                      to={`/borrow/application/${id}/${
                        buyerApplicationFormConfig.steps[routeIndex - 1].route
                      }`}
                      className="silta-form__pagination__btn-back"
                    >
                      <Button
                        className="d-flex align-items-center btn-back"
                        variant="link"
                      >
                        <BsChevronLeft className="me-2" />
                        Back
                      </Button>
                    </Link>
                  ) : <div />}
                  {routeIndex + 1 < buyerApplicationFormConfig.steps.length ? (
                    <Link
                      to={`/borrow/application/${id}/${
                        buyerApplicationFormConfig.steps[routeIndex + 1].route
                      }`}
                      className="silta-form__pagination__btn"
                    >
                      <Button
                        className="d-flex align-items-center btn-next"
                        variant="link"
                        color="primary"
                      >
                        Next
                        <BsChevronRight className="ms-2" />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      color="primary"
                      className="ps-4 pe-4 btn-submit-application"
                      onClick={() => {
                        handleSubmit();
                        !isValid && setError(true);
                        isValid && handleValid();
                      }}
                      disabled={applicationStateDisabled}
                      type="submit"
                    >
                      Submit application
                      <BsArrowRight className="ms-2 mb-1 font-weight-bold" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="footer--form footer--with-sidebar" />
      </div>
      <Modal
        show={submitModalShown}
        className="application-modal"
      >
        <Modal.Body className="text-center p-0">
          <div className="application-modal__icon">
            <BsCheck2Circle />
          </div>
          <div className="text-lg mb-2">Application submitted</div>
          <div className="text-sm text-gray mb-4">The initial phase of your Pre-qualification application has been submitted. The Silta team will review it and get back to you shortly.</div>
          <Link to="/borrow/loan-applications">
            <Button color="primary" className="w-100 mt-1 pt-2 pb-2">
              Finish
            </Button>
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}
