/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { find } from 'lodash-es';
import { useQuery } from '@apollo/client';
import { FiChevronDown } from 'react-icons/fi';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg'
import Layout from '../components/Layout';
import { FETCH_ALL_APPLICATIONS } from '../queries';
import Loading from '../components/Loading';
import one from '../assets/images/SDG-Icons/SDGGoal-01.png';
import two from '../assets/images/SDG-Icons/SDGGoal-02.png';
import three from '../assets/images/SDG-Icons/SDGGoal-03.png';
import four from '../assets/images/SDG-Icons/SDGGoal-04.png';
import five from '../assets/images/SDG-Icons/SDGGoal-05.png';
import six from '../assets/images/SDG-Icons/SDGGoal-06.png';
import seven from '../assets/images/SDG-Icons/SDGGoal-07.png';
import eight from '../assets/images/SDG-Icons/SDGGoal-08.png';
import nine from '../assets/images/SDG-Icons/SDGGoal-09.png';
import ten from '../assets/images/SDG-Icons/SDGGoal-10.png';
import eleven from '../assets/images/SDG-Icons/SDGGoal-11.png';
import twelve from '../assets/images/SDG-Icons/SDGGoal-12.png';
import thirteen from '../assets/images/SDG-Icons/SDGGoal-13.png';
import fourteen from '../assets/images/SDG-Icons/SDGGoal-14.png';
import fifteen from '../assets/images/SDG-Icons/SDGGoal-15.png';
import sixteen from '../assets/images/SDG-Icons/SDGGoal-16.png';
import seventeen from '../assets/images/SDG-Icons/SDGGoal-17.png';
import Cosol from '../assets/images/cosol.svg';
import Wenergy from '../assets/images/wenergy_logo.svg';
import Solenium from '../assets/images/solenium.png';
import { mockProject } from '../defaultData';
import HelpIcon from '../assets/images/icons/Help-icon-dark.svg';

function ProjectPage() {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [toggle, setToggle] = useState(false);
  const [showInfo, setShowInfo] = useState(true)

  const { loading, error } = useQuery(FETCH_ALL_APPLICATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      // ({ allApplications })
      const findProject = find(mockProject, { id });
      // const findProject = find(allApplications, { id });
      setProject(findProject);
    },
  });

  const ready = !(loading || error);

  useEffect(() => {
  }, [project]);

  // const currencyMillion = `${project.currency} ${project.projectloanamount / 1000000}M`;

  return (
    <Layout>
      {
        !ready
          ? <Loading />
          : (
            <section className="project-page">
              <div className="d-flex align-items-center justify-content-between loan-pools__header">
                <Link
                  to="/impact-marketplace"
                  className="project__header__link"
                >
                  <span className="me-2">Impact marketplace</span>
                </Link>
                <span
                  className="project__header__link project__header__link--active"
                >
                  Project profile
                </span>
              </div>
              <div className="project">
                <div className="project__header">
                  {
                    project.companyname === 'Solenium' && showInfo &&
                    <Alert variant="danger" className="info-alert d-flex">
                     This project is 1 of 5 projects from the same borrower in a similar asset category. These projects can be bundled as full or different subsets in a single finance transaction.
                     <CgClose onClick={() => setShowInfo(false)}/>
                    </Alert>
                  }
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex">
                      <div className="financier__header__logo-container d-flex align-items-center">
                      {
                        project.companyname === 'Solenium' && 
                        <img
                        alt={`${project.projectname} logo`}
                        className="project-list__project__logo-solenium w-100 d-inline"
                        src={Solenium}
                      />
                      }

                      {
                        project.companyname === 'COSOL Condominio Solar Ltda' && 
                        <img
                        alt={`${project.projectname} logo`}
                        className="project-list__project__logo h-100 d-inline"
                        src={Cosol}
                      />
                      }
                      {
                        project.companyname === 'Archipelago Renewables Corporation' && 
                        <img
                        alt={`${project.projectname} logo`}
                        className="project-list__project__logo h-100 d-inline"
                        src={Wenergy}
                      />
                      }
                      </div>
                      <div>
                        <div className="financier__header__name my-2">{project?.applicationname}</div>
                        <div className="financier__header__company">
                          {project?.companyname}
                          <span className="vertical-separator">|</span>
                          <div
                            className={`project-list__project__tag me-2
                            ${project?.state === 'PREQUALIFIED' && 'tag-prequalified'} 
                            ${project?.state === 'VOTING_OPEN' && 'tag-scored'} 
                            ${project?.state === 'PROJECT_FUNDED' && 'tag-funded'}
                            ${project?.state === 'FINANCING' && 'tag-financing'}
                            `}
                          >
                            {project?.state === 'PREQUALIFIED' && 'Pre-qualified'}
                            {project?.state === 'VOTING_OPEN' && 'Scored by Silta'}
                            {project?.state === 'PROJECT_FUNDED' && 'Funded'}
                            {project?.state === 'FINANCING' && 'Financing'}

                          </div>
                          {
                  project.companyname === 'Solenium' &&
                  <div
                  className='project-list__project__tag project-list__project__tag-bundled tag-bundled' 
                >
                   <span>
                  Bundled 
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="bundled" style={{ opacity: 1 }}>
                     <p className="btn-tooltip-name">
                       This project can be bundled with others Unergy projects
                       </p>
                    <p className="btn-tooltip-description">
                    This project is 1 of 5 projects from the same borrower in a similar asset category. These projects can be bundled as full or different subsets in a single finance transaction. 
                      </p>
                  </Tooltip>
    )}
              >
                <img src={HelpIcon} alt="Help icon" className="tooltip-help ms-1" />
              </OverlayTrigger>
            </span>
                </div>
                }
                        </div>
                      </div>
                    </div>
                    <div>
                      {
                        project?.website && <a href={project?.website} target="_blank" type="button" className="rrss-btn me-3" rel="noreferrer">Website</a>
                      }
                      {
                        project?.linkedin && <a href={project?.linkedin} target="_blank" type="button" className="rrss-btn me-3" rel="noreferrer">Linkedin</a>
                      }
                      {
                        project?.twitter && <a href={project?.Twitter} target="_blank" type="button" className="rrss-btn me-3" rel="noreferrer">Twitter</a>
                      }
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    role="button"
                    className="financier__info-box__sdgs"
                    onClick={() => setToggle(!toggle)}
                  >
                    SDGs this project is contributing to
                    <FiChevronDown className={toggle && 'icon-toggle'} />
                  </div>

                  <div className={`project__info-box container__sdgs ${toggle ? 'd-flex container__sdgs--toggle' : 'd-none'}`}>
                    <div className="project__info-box__container">
                    <div className="project__info-box__text d-flex flex-wrap">
                        {
                  project?.sdggoals?.map((goal) => {
                    if (goal === 'No poverty') {
                      return (
                        <div key="goal-1" className="financier__info-box__sdgs-imgs">
                          <img src={one} alt="Goal 1 - No Poverty" />
                          <p>Goal 1 - No Poverty</p>
                        </div>
                      );
                    }

                    if (goal === 'Zero hunger') {
                      return (
                        <div key="goal-2" className="financier__info-box__sdgs-imgs">
                          <img src={two} alt="Goal 2 - Zero Hunger" />
                          <p>Goal 2 - Zero Hunger</p>
                        </div>
                      );
                    }

                    if (goal === 'Good health and well-being') {
                      return (
                        <div key="goal-3" className="financier__info-box__sdgs-imgs">
                          <img src={three} alt="Goal 3 - Good Health and Well-being" />
                          <p>Goal 3 - Good Health and Well-being</p>
                        </div>
                      );
                    }

                    if (goal === 'Quality education') {
                      return (
                        <div key="goal-4" className="financier__info-box__sdgs-imgs">
                          <img src={four} alt="Goal 4 - Quality Education" />
                          <p>Goal 4 - Quality Education</p>
                        </div>
                      );
                    }

                    if (goal === 'Gender Equality') {
                      return (
                        <div key="goal-5" className="financier__info-box__sdgs-imgs">
                          <img src={five} alt="Goal 5 - Gender Equality" />
                          <p>Goal 5 - Gender Equality</p>
                        </div>
                      );
                    }

                    if (goal === 'Clean water and sanitation') {
                      return (
                        <div key="goal-6" className="financier__info-box__sdgs-imgs">
                          <img src={six} alt="Goal 6 - Clean Water and Sanitation" />
                          <p>Goal 6 - Clean Water and Sanitation</p>
                        </div>
                      );
                    }

                    if (goal === 'Affordable and clean energy') {
                      return (
                        <div key="goal-7" className="financier__info-box__sdgs-imgs">
                          <img src={seven} alt="Goal 7 - Affordable and Clean Energy" />
                          <p>Goal 7 - Affordable and Clean Energy</p>
                        </div>
                      );
                    }

                    if (goal === 'Decent work and economic growth') {
                      return (
                        <div key="goal-8" className="financier__info-box__sdgs-imgs">
                          <img src={eight} alt="Goal 8 - Decent Work and Economic Growth" />
                          <p>Goal 8 - Decent Work and Economic Growth</p>
                        </div>
                      );
                    }

                    if (goal === 'Industry, innovation and infrastructure') {
                      return (
                        <div key="goal-9" className="financier__info-box__sdgs-imgs">
                          <img src={nine} alt="Goal 9 - Industry, Innovation and Infrastructure" />
                          <p>Goal 9 - Industry, Innovation and Infrastructure</p>
                        </div>
                      );
                    }

                    if (goal === 'Reduced inequality') {
                      return (
                        <div key="goal-10" className="financier__info-box__sdgs-imgs">
                          <img src={ten} alt="Goal 10 - Reduced Inequalities" />
                          <p>Goal 10 - Reduced Inequalities</p>
                        </div>
                      );
                    }

                    if (goal === 'Sustainable cities and communities') {
                      return (
                        <div key="goal-11" className="financier__info-box__sdgs-imgs">
                          <img src={eleven} alt="Goal 11 - Sustainable Cities and Communities" />
                          <p>Goal 11 - Sustainable Cities and Communities</p>
                        </div>
                      );
                    }

                    if (goal === 'Responsible consumption and production') {
                      return (
                        <div key="goal-12" className="financier__info-box__sdgs-imgs">
                          <img src={twelve} alt="Goal 12 - Responsible Consumption and Production" />
                          <p>Goal 12 - Responsible Consumption and Production</p>
                        </div>
                      );
                    }

                    if (goal === 'Climate action') {
                      return (
                        <div key="goal-13" className="financier__info-box__sdgs-imgs">
                          <img src={thirteen} alt="Goal 13 - Climate Action" />
                          <p>Goal 13 - Climate Action</p>
                        </div>
                      );
                    }

                    if (goal === 'Life below water') {
                      return (
                        <div key="goal-14" className="financier__info-box__sdgs-imgs">
                          <img src={fourteen} alt="Goal 14 - Life Below Water" />
                          <p>Goal 14 - Life Below Water</p>
                        </div>
                      );
                    }

                    if (goal === 'Life on land') {
                      return (
                        <div key="goal-15" className="financier__info-box__sdgs-imgs">
                          <img src={fifteen} alt="Goal 15 - Life on Land" />
                          <p>Goal 15 - Life on Land</p>
                        </div>
                      );
                    }

                    if (goal === 'Peace, justice and strong institutions') {
                      return (
                        <div key="goal-16" className="financier__info-box__sdgs-imgs">
                          <img src={sixteen} alt="Goal 16 - Peace, Justice and Strong Institutions" />
                          <p>Goal 16 - Peace, Justice and Strong Institutions</p>
                        </div>
                      );
                    }

                    if (goal === 'Partnerships for the goals') {
                      return (
                        <div key="goal-17" className="financier__info-box__sdgs-imgs">
                          <img src={seventeen} alt="Goal 17 - Partnerships for the Goals" />
                          <p>Goal 17 - Partnerships for the Goals</p>
                        </div>
                      );
                    }
                  })
                }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project__info-box">
                  <div className="project__info-box__container">
                    <div className="project__info-box__label">Summary</div>
                    <div className="project__info-box__text">{project?.summary}</div>
                  </div>
                </div>

                <div className="project__info-box">
                  <div className="project__info-box__container">
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="project__info-box-sm">
                        <div className="project__info-box__label">Sector</div>
                        <div className="project__info-box__bold-text">{project?.projectsector}</div>
                      </div>
                      <div className="project__info-box-sm">
                        <div className="project__info-box__label">Project type</div>
                        <div className="project__info-box__bold-text">{project?.projecttype}</div>
                      </div>
                      <div className="project__info-box-sm mr-5">
                        <div className="project__info-box__label">
                          Project stage
                        </div>
                        <div className="project__info-box__bold-text">{project?.projecttimetable}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project__info-box">
                  <div className="project__info-box__container">
                    <div className="project__info-box__label">
                      About the project
                    </div>
                    <div className="project__info-box__text">
                      {project?.projectdescription?.split('\n').map(((part, idx) => <p key={`developersDescription-${idx}`}>{part}</p>))}
                    </div>
                  </div>
                </div>
                <div className="project__info-box">
                  <div className="project__info-box__container">
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="project__info-box-sm">
                        <div className="project__info-box__label">
                          Loan type
                        </div>
                        <div className="project__info-box__bold-text">{project?.projectfinancingtype}</div>
                      </div>
                      <div className="project__info-box-sm">
                        <div className="project__info-box__label">Requested loan amount</div>
                        <div className="project__info-box__bold-text">
                          {project?.projectloanamount}
                        </div>
                      </div>
                      <div className="mr-5 project__info-box-sm">
                        <div className="project__info-box__label">
                          Preferred loan term
                        </div>
                        <div className="project__info-box__bold-text">{project?.projectloanperiod}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project__info-box">
                  <div className="project__info-box__container">
                    <div className="project__info-box__label">
                      About the developer
                    </div>
                    <div className="project__info-box__text">
                      {project?.projectdeveloperdescription?.split('\n').map(((part, idx) => <p key={`developersDescription-${idx}`}>{part}</p>))}
                    </div>
                  </div>
                </div>

              </div>
            </section>
          )
}
    </Layout>
  );
}

export default ProjectPage;
