/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { map } from 'lodash-es';
import { countries } from 'country-data';
import ErrorLabel from '../ErrorMessage';
import { FormText, FormCheck, FormWeblink } from './index';
import FormSelect from './FormSelect';
import FormFiles from './FormFiles';

/**
  companyname
  companytype
  customcompanytype
  companywebsite
  companyid
  companyhastaxnumber
  companytaxnumber
  street
  city
  country
  postalcode
*/

const companyTypes = ['Independent developer', 'Subsidiary', 'Joint venture', 'Special purpose vehicle (SPV)', 'Other'];


const tooltipTIN = { title: 'Taxation identification number (TIN)', text: 'TIN is a unique code issued to the company by the government authorities in the country of tax residence.' };
const tooltipBUID = { title: 'Business identification number', text: 'Identification number from the jurisdiction where the company is set up.' };

// eslint-disable-next-line no-sequences
const countrylists = countries.all.map((country) => {
  const { name, countryCallingCodes } = country

  if(countryCallingCodes.length === 0 
    || name === 'Ascension Island'
    || name === 'Anguilla'
    || name === 'American Samoa'
    || name === 'Åland Islands'
    || name === 'Saint Barthélemy'
    || name === 'Bermuda'
    || name === 'Bonaire, Saint Eustatius And Saba'
    || name === 'Cocos (Keeling) Islands'
    || name === 'Cook Islands'
    || name === 'Christmas Island'
    || name === 'Falkland Islands'
    || name === 'French Guiana'
    || name === 'Gibraltar'
    || name === 'Guadeloupe'
    || name === 'Isle Of Man'
    || name === 'Northern Mariana Islands'
    || name === 'Martinique'
    || name === 'Montserrat'
    || name === 'Reunion'
    || name === 'Saint Helena, Ascension And Tristan Da Cunha'
    || name === 'Svalbard And Jan Mayen'
    || name === 'Tristan de Cunha'
    || name === 'Wallis And Futuna'
    || name === 'European Union' 
    || name === 'France, Metropolitan' 
    || name === 'Western Sahara'
    || name === 'British Indian Ocean Territory'
    || name === 'United States Minor Outlying Islands'
    || name === 'Palestinian Territory, Occupied'
    ){
    return false
  }

  return { 'label': name, 'value': name}
})

const countryOptions = countrylists.filter((country) => country !== false)

function CompanyInformation({ applicationStateDisabled }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <Field name="companyname">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert company name"
            className={meta.touched && meta.error ? 'error' : null}
            label="Name of the project company"
          />
        )}
      </Field>
      <Field name="companytype">
        {({
          field,
          meta,
        }) => (
          <>
            <label className="form-label mb-2">
              Company type
            </label>
            {map(companyTypes, (type, index) => (
              <FormCheck
                key={index}
                id={type + index}
                {...field}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
                type="radio"
                className={`${meta.touched && meta.error ? 'error-radio' : null} ${index + 1 === companyTypes.length ? 'mb-0' : 'mb-2'}`}
                label={type}
                classNameCheck="no-bg"
              />
            ))}
          </>
        )}
      </Field>
      <Field name="customcompanytype">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Specify company type"
            className={meta.touched && meta.error ? 'error' : null}
            disabled={!(values.companytype === 'Other')}
            hint="If other, specify the company type here"
          />
        )}
      </Field>
      <ErrorMessage name="customcompanytype" component={ErrorLabel} className="checkbox-error" />
      <ErrorMessage name="companytype" component={ErrorLabel} className="checkbox-error" />
      <div className="form-separator" />
      <Field name="companywebsite">
        {({
          field,
          meta,
        }) => (
          <FormWeblink
            {...field}
            placeholder="www.companywebsite.com"
            className={meta.touched && meta.error ? 'error' : null}
            label="Company website"
          />
        )}
      </Field>
      <Field name="companyid">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert business identification number"
            className={meta.touched && meta.error ? 'error' : null}
            label="Business identification number"
            tooltip={tooltipBUID}
          />
        )}
      </Field>
      <Field name="companylogofiles">
        {({
          field,
          meta,
        }) => (
          <div className="w-100">
            <div className="form-label">
              Upload the company logo
            </div>
            <FormFiles
              {...field}
              filesAccepted="PNG or JPG up to 2MB"
              accept="image/jpeg, image/png"
              maxSize={2097152}
              uploadType="COMPANY_LOGO"
              className={meta.touched && meta.error ? 'error' : null}
              style={{ maxWidth: '100%' }}
              disabled={applicationStateDisabled}
            />
          </div>
        )}
      </Field>
      <Field name="street">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert street address"
            className={meta.touched && meta.error ? 'error' : null}
            label="Registered company address"
          />
        )}
      </Field>
      <Field name="country">
        {({
          field,
          meta,
        }) => (
          <FormSelect
            {...field}
            onChange={(val) => setFieldValue(field.name, val.value)}
            className={meta.touched && meta.error ? 'error' : null}
            label="Country"
            options={countryOptions}
            selectCountry
            placeholder="Select country"
            disabled={applicationStateDisabled}
          />
        )}
      </Field>
      <Field name="city">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert city"
            className={meta.touched && meta.error ? 'error' : null}
            label="City"
          />
        )}
      </Field>
      <div className="d-flex w-100 justify-content-between">
        <Field name="postalcode">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert postal code"
              className={meta.touched && meta.error ? 'error' : null}
              label="Postal code"
            />
          )}
        </Field>
        <Field name="usstate">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert state"
              className={meta.touched && meta.error ? 'error' : null}
              label="State (if applicable)"
              nomargin
            />
          )}
        </Field>
      </div>
      <div className="form-separator mt-4" />
      <div style={{ marginTop: 65 }}>
        <Field name="companytaxnumber">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert tax number"
              className={meta.touched && meta.error ? 'error' : null}
              label="Taxation identification number (TIN)"
              tooltip={tooltipTIN}
            />
          )}
        </Field>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <Field name="missingtaxnumber">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              textarea
              placeholder="Explain why the company has no TIN"
              className={meta.touched && meta.error ? 'error' : null}
              label="Reason for a missing TIN, if applicable"
              // disabled={values.companyhastaxnumber !== 'Yes'}
              maxLength="250"
              hint="Max 250 characters"
            />
          )}
        </Field>
      </div>
    </>
  );
}

export default CompanyInformation;
