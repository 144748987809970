/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiChevronDown, FiLogOut } from 'react-icons/fi';
import logo from '../assets/images/logo.svg';
import { useAuth } from '../providers/AuthProvider';
import Footer from './Footer';

export default function Layout({ children }) {
  const [toggle, setToggle] = useState(false);
  const { location: { pathname } } = useHistory();
  const { user = {}, logout } = useAuth();

  const dropdownRef = useRef();
  const dropdownBtn = useRef();
  
  useEffect(() => {
    function handler(event) {
      if (!dropdownRef.current?.contains(event.target)
      && !dropdownBtn.current?.contains(event.target)) {
        setToggle(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);


  const handleToggle = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const handleLogout = () => {
    logout();
    setToggle(false);
  };


  return (
    <div className="silta">
      <nav className="nav  d-flex align-items-center justify-content-center">
        <div className="container w-100 d-flex justify-content-center">
          <div className="nav__content d-flex align-items-center justify-content-between">
            <div>
              <Link to="/impact-marketplace">
                <img src={logo} alt="Silta home" />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <div>
                {
                user
              && (
              <Link
                to="/borrow/loan-applications"
                className={`link-nav link-nav--animation ${pathname === '/borrow/loan-applications' && 'nav__content__active'}`}
              >
                Loan applications
              </Link>
              )
              }
              </div>
              <div>
                <Link
                  to="/impact-marketplace"
                  className={`link-nav link-nav--animation ${pathname === '/impact-marketplace' && 'nav__content__active'}`}
                >
                  Impact marketplace
                </Link>
              </div>
              {
              user
                ? (
                  <div className="nav__toggle nav__toggle-user" ref={dropdownBtn}>
                    <div
                      className={`nav-user-btn ${toggle && 'nav-user-btn--toggle'}`}
                      onClick={handleToggle}
                    >
                      {user.username}
                      <FiChevronDown className="arrow-down" />
                    </div>
                    <div
                      role="button"
                      className={`nav__toggle-dropdown ${toggle && 'nav__toggle-dropdown--toggle'}`}
                      onClick={handleLogout}
                      ref={dropdownRef}
                    >
                      <div className="nav__toggle-dropdown--toggle--container">
                        <FiLogOut />
                        Logout
                      </div>
                    </div>
                  </div>
                )
                : (
                  <Link
                    to="/login"
                    className="nav-login-btn"
                  >
                    Borrower log in
                  </Link>
                )

            }
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid position-relative d-flex ps-0 pe-0">
        <div className="silta-page">
          {children}
        </div>
      </div>
      <Footer className="footer" />
    </div>
  );
}
