import React, { useState, useContext, useMemo } from 'react';

export const DisclaimerContext = React.createContext([]);

export const useDisclaimerContext = () => useContext(DisclaimerContext);

export function DisclaimerProvider({ children }) {
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const disclaimers = useMemo(
    () => ({
      openDisclaimer,
      handleClose: () => setOpenDisclaimer(false) }),
    [openDisclaimer],
  );

  return (
    <DisclaimerContext.Provider value={disclaimers}>
      {children}
    </DisclaimerContext.Provider>
  );
}

export default DisclaimerProvider;