import { gql } from '@apollo/client';

export const ME = gql`
  query {
    me {
      username
      email
      role
      id
      active
    }
  }
`;

export const NONCE = gql`
  query userNonce($address: String!) {
    userNonce(address: $address)
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  query activate($token: String!) {
    activate(token: $token)
  }
`;

export const CREATE_USER = gql`
  mutation createUser($email: String!, $username: String!, $password: String!, $termsAccepted: Boolean!, $role: String ) {
    createUser(email: $email, username: $username, password: $password, termsAccepted: $termsAccepted, role: $role) {
      username
      role
      id
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      value
      user {
        username
        email
        role
        id
        active
      }
    }
  }
`;

export const WALLET_LOGIN = gql`
  mutation loginWithWallet($signature: String!, $publicAddress: String!) {
    loginWithWallet(signature: $signature, publicAddress: $publicAddress) {
      value
      username
    }
  }
`;

export const SUBMIT_APPLICATION = gql`
  mutation SubmitApplication($applicationId: String!, $applicationname: String!) {
    submitApplication(applicationId: $applicationId, applicationname: $applicationname) {
      id
    }
  }
`;

export const FETCH_LOAN_APPLICATIONS = gql`
  query myApplications {
    myApplications {
      applicationname
      deleted
      id
      state
      projectname
      companylogofiles
    }
  }
`;

export const FETCH_ALL_LOAN_APPLICATIONS = gql`
  query allApplications {
    allApplications {
      applicationname
      deleted
      id
      state
      projectname
    }
  }
`;

export const EDIT_LOAN_APPLICATION = gql`
  mutation EditApplication(
    $applicationId: String!
      $applicationname: String
      $firstname: String
      $middlename: String
      $lastname: String
      $role: String
      $customrole: String
      $dateofbirth: String
      $phonenumber: String
      $email: String
      $linkedin: String
      $companyname: String
      $companytype: String
      $customcompanytype: String
      $companywebsite: String
      $companyid: String
      $street: String
      $country: String
      $city: String
      $postalcode: String
      $usstate: String
      $companytaxnumber: String
      $missingtaxnumber: String
      $projectname: String
      $alternateprojectname: String
      $projectlocation: String
      $projectsector: String
      $projecttype: String
      $achieveimpact: String
      $sdggoals: [String]
      $projectimpactdescription: String
      $projectdrawdown: String
      $projectfinancingtype: String
      $projectdescription: String
      $projectdeveloperdescription: String
      $projectdeveloperreferences: String
      $projecttotalcapital: String
      $projectloanamount: String
      $currency: String
      $projectloanperiod: String
      $projectinterestrate: String
      $projecttimetable: String
      $alternateprojecttimetable: String
      $projectConstructionStartDate: String
      $projectOperationsStartDate: String
      $projectOperationsLength: String
      $projectmodality: String
      $governmentsupport: String
      $governmentsupportdescription: String
      $projectirr: String
      $projectequitydebtratio: String
      $projectLeadershipLinkedIn: [String]
      $projectLeadershipTwitter: [String]
      $projectrelevantlinks: [String]
  ) {
    editApplication (
      applicationId: $applicationId
      applicationname: $applicationname
      firstname: $firstname
      middlename: $middlename
      lastname: $lastname
      role: $role
      customrole: $customrole
      dateofbirth: $dateofbirth
      phonenumber: $phonenumber
      email: $email
      linkedin: $linkedin
      companyname: $companyname
      companytype: $companytype
      customcompanytype: $customcompanytype
      companywebsite: $companywebsite
      companyid: $companyid
      street: $street
      country: $country
      city: $city
      postalcode: $postalcode
      usstate: $usstate
      companytaxnumber: $companytaxnumber
      missingtaxnumber: $missingtaxnumber
      projectname: $projectname
      alternateprojectname: $alternateprojectname
      projectlocation: $projectlocation
      projectsector: $projectsector
      projecttype: $projecttype
      achieveimpact: $achieveimpact
      sdggoals: $sdggoals
      projectimpactdescription: $projectimpactdescription
      projectdrawdown: $projectdrawdown
      projectfinancingtype: $projectfinancingtype
      projectdescription: $projectdescription
      projectdeveloperdescription: $projectdeveloperdescription
      projectdeveloperreferences: $projectdeveloperreferences
      projecttotalcapital: $projecttotalcapital
      projectloanamount: $projectloanamount
      currency: $currency
      projectloanperiod: $projectloanperiod
      projectinterestrate: $projectinterestrate
      projecttimetable: $projecttimetable
      alternateprojecttimetable: $alternateprojecttimetable
      projectConstructionStartDate: $projectConstructionStartDate
      projectOperationsStartDate: $projectOperationsStartDate
      projectOperationsLength: $projectOperationsLength
      projectmodality: $projectmodality
      governmentsupport: $governmentsupport
      governmentsupportdescription: $governmentsupportdescription
      projectirr: $projectirr
      projectequitydebtratio: $projectequitydebtratio
      projectLeadershipLinkedIn: $projectLeadershipLinkedIn
      projectLeadershipTwitter: $projectLeadershipTwitter
      projectrelevantlinks: $projectrelevantlinks
    ) {
      id
      applicationname
      firstname
      middlename
      lastname
      role
      customrole
      dateofbirth
      phonenumber
      email
      linkedin
      companyname
      companytype
      customcompanytype
      companywebsite
      companyid
      street
      country
      city
      postalcode
      usstate
      companytaxnumber
      missingtaxnumber
      projectname
      alternateprojectname
      projectlocation
      projectsector
      projecttype
      achieveimpact
      sdggoals
      projectimpactdescription
      projectdrawdown
      projectfinancingtype
      projectdescription
      projectdeveloperdescription
      projectdeveloperreferences
      projecttotalcapital
      projectloanamount
      currency
      projectloanperiod
      projectinterestrate
      projecttimetable
      alternateprojecttimetable
      projectConstructionStartDate
      projectOperationsStartDate
      projectOperationsLength
      projectmodality
      governmentsupport
      governmentsupportdescription
      projectirr
      projectequitydebtratio
      projectLeadershipLinkedIn
      projectLeadershipTwitter
      projectrelevantlinks
    }
  }
`;

export const FETCH_ADMIN_APPLICATION = gql`
  query Application($applicationId: String!) {
    application (applicationId: $applicationId) {
      applicationname
      state
      firstname
      middlename
      lastname
      role
      customrole
      dateofbirth
      phonenumber
      email
      linkedin
      companyname
      companytype
      customcompanytype
      companywebsite
      companyid
      street
      country
      city
      postalcode
      usstate
      companytaxnumber
      missingtaxnumber
      registrationDocument
      shareholders
      officerslist
      authorizationletter
      structurechart
      groupstructure
      sanctions
      companylogofiles
      projectname
      alternateprojectname
      projectlocation
      projectsector
      projecttype
      achieveimpact
      sdggoals
      projectimpactdescription
      projectdrawdown
      projectfinancingtype
      projectdescription
      projectdeveloperdescription
      projectdeveloperreferences
      projectreferencefiles
      projecttotalcapital
      projectloanamount
      currency
      projectloanperiod
      projectinterestrate
      projecttimetable
      alternateprojecttimetable
      projectConstructionStartDate
      projectOperationsStartDate
      projectOperationsLength
      projectmodality
      governmentsupport
      governmentsupportdescription
      projectirr
      projectequitydebtratio
      projectLeadershipLinkedIn
      projectLeadershipTwitter
      projectrelevantlinks
    }
  }
`;

export const FETCH_LOAN_APPLICATION = gql`
  query MyApplication($applicationId: String!) {
    myApplication (applicationId: $applicationId) {
      applicationname
      state
      firstname
      middlename
      lastname
      role
      customrole
      dateofbirth
      phonenumber
      email
      linkedin
      companyname
      companytype
      customcompanytype
      companywebsite
      companyid
      street
      country
      city
      postalcode
      usstate
      companytaxnumber
      missingtaxnumber
      registrationDocument
      shareholders
      officerslist
      authorizationletter
      structurechart
      groupstructure
      sanctions
      companylogofiles
      projectname
      alternateprojectname
      projectlocation
      projectsector
      projecttype
      achieveimpact
      sdggoals
      projectimpactdescription
      projectdrawdown
      projectfinancingtype
      projectdescription
      projectdeveloperdescription
      projectdeveloperreferences
      projectreferencefiles
      projecttotalcapital
      projectloanamount
      currency
      projectloanperiod
      projectinterestrate
      projecttimetable
      alternateprojecttimetable
      projectConstructionStartDate
      projectOperationsStartDate
      projectOperationsLength
      projectmodality
      governmentsupport
      governmentsupportdescription
      projectirr
      projectequitydebtratio
      projectLeadershipLinkedIn
      projectLeadershipTwitter
      projectrelevantlinks
    }
  }
`;

export const UPLOAD_SINGLE_FILE = gql`
  mutation SingleUpload($file: Upload!, $applicationId: String, $uploadType: UploadType) {
    singleUpload(file: $file, applicationId: $applicationId, uploadType: $uploadType) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPLOAD_MULTIPLE_FILES = gql`
  mutation MultiUpload($files: [Upload!]!, $applicationId: String, $uploadType: UploadType) {
    multiUpload(files: $files, applicationId: $applicationId, uploadType: $uploadType) {
      filename
      mimetype
      encoding
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteUpload($filename: String, $applicationId: String, $uploadType: UploadType) {
    deleteUpload(filename: $filename, applicationId: $applicationId, uploadType: $uploadType)
  }
`;

export const PROCESS_APPLICATION = gql`
  mutation ProcessApplication($applicationId: String!, $state: String!, $mailContent: String) {
    processApplication(applicationId: $applicationId, state: $state, mailContent: $mailContent)
  }
`;

export const CREATE_APPLICATION = gql`
  mutation CreateApplication($applicationname : String!){
    createApplication(applicationname: $applicationname){
      applicationname
      id
      firstname
      middlename
      lastname
      role
      customrole
      dateofbirth
      phonenumber
      email
      linkedin
      companyname
      companytype
      customcompanytype
      companywebsite
      companyid
      street
      country
      city
      postalcode
      usstate
      companytaxnumber
      missingtaxnumber
      projectname
      alternateprojectname
      projectlocation
      projectsector
      projecttype
      achieveimpact
      sdggoals
      projectimpactdescription
      projectdrawdown
      projectfinancingtype
      projectdescription
      projectdeveloperdescription
      projectdeveloperreferences
      projecttotalcapital
      projectloanamount
      currency
      projectloanperiod
      projectinterestrate
      projecttimetable
      alternateprojecttimetable
      projectConstructionStartDate
      projectOperationsStartDate
      projectOperationsLength
      projectmodality
      governmentsupport
      governmentsupportdescription
      projectirr
      projectequitydebtratio
      projectLeadershipLinkedIn
      projectLeadershipTwitter
      projectrelevantlinks
    }
  }
`;

export const FETCH_ALL_APPLICATIONS = gql`
  query allApplications {
      allApplications {
        id
        projectname
        summary
        companylogofiles
        applicationname
        companyname
        state
        country
        projectfinancingtype
        projectinterestrate
        projectlocation
        projectdescription
        projectsector
        companywebsite
        projectdeveloperdescription
        projectdeveloperreferences
        projecttype
        alternateprojecttype
        projectloanamount
        currency
        projectloanperiod
        projecttimetable
        sdggoals
    }
  }
  `;

export const DELETE_APPLICATION = gql`
mutation deleteApplication($applicationId: String!){
  deleteApplication(applicationId: $applicationId)
}
` 

export const RESET_PASSWORD_EMAIL = gql`
  query resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
   mutation resetPassword($passwordResetToken: String!, $newPassword: String!) {
    resetPassword(passwordResetToken: $passwordResetToken, newPassword: $newPassword)
  }
`;
