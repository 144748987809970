import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Hint from '../../assets/images/icons/help.svg';

function FormCheck(
  // eslint-disable-next-line max-len
  { value, name, id, type, label, disabled, className, classNameCheck, onChange, onBlur, hint, checked },
) {
  const IsChecked = value === label;

  return (
    <Form.Group
      controlId={id}
      className={`d-flex align-items-baseline flex-row silta-form-check ${disabled ? 'disabled' : null } ${className}`}
    >
      <Form.Check
        id={id}
        name={name}
        // custom
        type={type}
        label={label}
        className={classNameCheck === 'no-bg' ? 'no-bg-sigunp' : value === 'true' && 'clicked'}
        value={label}
        onChange={onChange}
        checked={checked || (type === 'radio' && IsChecked) || null}
        onBlur={onBlur}
        disabled={disabled || null}
      />
      <span>
        { hint
      && (
        <span className="ms-2" style={{ position: 'relative' }}>
          <OverlayTrigger
            placement="right"
            overlay={(
              <Tooltip style={{ opacity: 1, position: 'absolute' }}>
                <p className="btn-tooltip-name">
                  {hint.title}
                </p>
                <p className="btn-tooltip-description">{hint.text}</p>
              </Tooltip>
)}
          >
            <img src={Hint} alt="Help icon" />
          </OverlayTrigger>
        </span>
      )}
      </span>
    </Form.Group>
  );
}

export default FormCheck;
