/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { BsArrowLeftShort } from 'react-icons/bs';
import { CREATE_USER } from '../queries';
import { FormCheck, FormText } from '../components/Form';
import CheckEmail from '../components/checkEmail';
import Logo from '../assets/images/icons/signin-logo.svg';
import ErrorAlert from '../components/ErrorAlert';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';

const validationSchema = yup.object({
  name: yup
    .string('Enter your username')
    .required('Username is required')
    .min(4, 'Username should be at least 4 characters'),
  email: yup
    .string('Enter your email')
    .email('Invalid email format')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be at least 8 characters')
    .required('Password is required'),
  passwordConfirmation: yup
    .string('Re-enter your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Retyping password is required'),
  termsAccepted: yup
    .boolean()
    .oneOf([true], 'Accepting the terms is required'),
});

function SignUp() {
  const [userEmail, setUserEmail] = useState('');
  const [submitForm, setSubmitForm] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const [signup] = useMutation(CREATE_USER, {
    onError: (error) => {
      console.log(error, 'error on create');
      setRegisterError(error);
    },
    onCompleted: (data, errors) => {
      console.log(data, errors, 'oNCompleted');
      if (data.createUser) {
        history.push(`/activate-account/${data.createUser.id}`);
        setUserEmail(data.createUser.email);
        setSubmitForm(true);
      } else setRegisterError('There was an error creating your account, perhaps this account is already registered ?');
    },
  });

  const handleOpen = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <section className="account">
      {!submitForm ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-5">
              <div className="w-100 text-center">
                <Link to="/">
                  <img src={Logo} alt="Silta logo" className="account__logo" />
                </Link>
                <h2 className="account__title">Create an account</h2>
                <p className="account__text">Enter the details to create your account</p>
              </div>
              {registerError && <ErrorAlert error={registerError} />}
              <div className="w-100">
                <div>
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      password: '',
                      passwordConfirmation: '',
                      termsAccepted: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      signup({
                        variables: {
                          username: values?.name,
                          email: values?.email,
                          password: values?.password,
                          termsAccepted: values?.termsAccepted,
                        },
                      });
                      // submit to backend
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormText
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          className={
                            touched.name && errors.name ? 'error' : null
                          }
                          label="Name"
                        />

                        <FormText
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={
                            touched.email && errors.email ? 'error' : null
                          }
                          label="Email"
                        />
                        <FormText
                          type="password"
                          name="password"
                          placeholder="Create your password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className={touched.password && errors.password ? 'error' : null}
                          label="Password"
                          hint="Must be at least 8 characters"
                        />
                        <div className="mt-5">
                          <FormText
                            type="password"
                            name="passwordConfirmation"
                            placeholder="Verify your password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordConfirmation}
                            className={
                            touched.passwordConfirmation
                            && errors.passwordConfirmation
                              ? 'error'
                              : null
                          }
                            label="Repeat password"
                          />
                        </div>
                        <FormCheck
                          id="termsAccepted"
                          onChange={handleChange}
                          type="checkbox"
                          className={`
                            ${touched.termsAccepted
                            && errors.termsAccepted
                            ? 'error-checkbox'
                            : null}
                              my-1
                              `}
                          label={(
                            <div className="label-terms">
                              <span>I have read and accept the </span>
                              <button className="label-terms__btn" onClick={handleOpen}> terms and conditions</button>
                              <span> for the Silta dApp registration.</span>
                            </div>
                          )}
                          classNameCheck="no-bg-sigunp"
                        />
                        <div className="error-message-checkbox">{touched.termsAccepted && errors.termsAccepted && errors.termsAccepted}</div>

                        <Button
                          type="submit"
                          variant="primary"
                          className="account__btn my-4"
                        >
                          Sign Up
                        </Button>
                        <div className="mb-3 text-center">
                          Already have an account?&nbsp;
                          <Link to="/login">Log in</Link>
                        </div>
                        {
                        showModal && <TermsAndConditionsModal show={showModal} onHide={() => setShowModal(false)} />
                     }
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="login-back w-100 d-flex align-items-center justify-content-center">
                  <Link to="/login">
                    <BsArrowLeftShort />
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CheckEmail userEmail={userEmail} setSubmitForm={setSubmitForm} />
      )}
    </section>
  );
}

export default SignUp;
