import React from 'react';

function HomeBanner({ title, subtitle }) {
  return <div className="home d-flex align-items-center">
    <div>
      <p className="home_title">
        {title}
      </p>
      <p className="home_subtl">
        {subtitle}
      </p>
    </div>
  </div>
}

export default HomeBanner;
