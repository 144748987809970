import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

export default function Notification({ title, text, type = 'error' }) {
  return (
    <div className={`d-flex mb-3 notification notification--${type}`}>
      <BsExclamationCircle />
      <div>
        <div className="notification__title">
          {title}
        </div>
        <div className="notification__text">
          {text}
        </div>

      </div>
    </div>
  );
}
