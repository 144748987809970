/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React, { useState, createContext, useCallback, useEffect, useContext, useRef } from 'react'; 
import { useHistory } from 'react-router-dom';
import { Formik, Form, useFormikContext, Field } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import * as yup from 'yup';
import { debounce } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { BsXLg } from 'react-icons/bs';
import { buyerApplicationFormConfig } from '../formConfig';
import FormText from '../components/Form/FormText';
import FormSelect from '../components/Form/FormSelect';
import { EDIT_LOAN_APPLICATION, SUBMIT_APPLICATION, PROCESS_APPLICATION } from '../queries';
import { useAuth } from './AuthProvider';

const websiteRegex = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const phoneRegex = /\+?\(?([0-9]{3})\)?[-.]?\(?([0-9]{3})\)?[-.]?\(?([0-9]{4})\)?/;

const ApplicationFormValidationSchema = yup.object().shape({
  firstname: yup.string().required('Required').nullable(),
  middlename: yup.string().nullable(),
  lastname: yup.string().required('Required').nullable(),
  role: yup.string().required('Required').nullable(),
  customrole: yup.string().nullable(),
  dateofbirth: yup.string().matches(dateRegex, 'Invalid format').required('Required').nullable(),
  phonenumber: yup.string().matches(phoneRegex, 'Invalid format').required('Required').nullable(),
  email: yup.string().email('Invalid email').required('Required').nullable(),
  linkedin: yup.string().matches(websiteRegex, 'Invalid format').required('Required').nullable(),
  companyname: yup.string().required('Required').nullable(),
  companytype: yup.string().required('Required').nullable(),
  customcompanytype: yup.string().nullable(),
  companywebsite: yup.string().matches(websiteRegex, 'Invalid format').required('Required').nullable(),
  companyid: yup.string().required('Required').nullable(),
  street: yup.string().required('Required').nullable(),
  city: yup.string().required('Required').nullable(),
  country: yup.string().required('Required').nullable(),
  postalcode: yup.string().required('Required').nullable(),
  usstate: yup.string().nullable(),
  companytaxnumber: yup.string().nullable(),
  missingtaxnumber: yup.string().nullable(),
  projectname: yup.string().required('Required').nullable(),
  alternateprojectname: yup.string().nullable(),
  projectlocation: yup.string().required('Required').nullable(),
  projectsector: yup.string().required('Required').nullable(),
  achieveimpact: yup.string().required('Required').nullable(),
  sdggoals: yup.array().min(1, 'Please select at least one option').nullable(),
  projectimpactdescription: yup.string().required('Required').nullable(),
  projecttype: yup.string().required('Required').nullable(),
  projectdrawdown: yup.string().required('Required').nullable(),
  projectfinancingtype: yup.string().required('Required').nullable(),
  projectdescription: yup.string().required('Required').nullable(),
  projectdeveloperdescription: yup.string().required('Required').nullable(),
  projectdeveloperreferences: yup.string().nullable(),
  projecttotalcapital: yup.string().required('Required').nullable(),
  projectloanamount: yup.string().required('Required').nullable(),
  projectloanperiod: yup.string().required('Required').nullable(),
  projectinterestrate: yup.string().required('Required').nullable(),
  projecttimetable: yup.string().required('Required').nullable(),
  alternateprojecttimetable: yup.string().nullable(),
  projectConstructionStartDate: yup.string().required('Required').nullable(),
  projectOperationsStartDate: yup.string().required('Required').nullable(),
  projectOperationsLength: yup.string().required('Required').nullable(),
  projectmodality: yup.string().required('Required').nullable(),
  governmentsupport: yup.string().required('Required').nullable(),
  governmentsupportdescription: yup.string().nullable(),
  projectirr: yup.string().required('Required').nullable(),
  projectequitydebtratio: yup.string().required('Required').nullable(),
  projectLeadershipLinkedIn: yup.array().nullable(),
  projectLeadershipTwitter: yup.array().nullable(),
  projectrelevantlinks: yup.array().nullable(),
  registrationDocument: yup.array().min(1, 'You must upload at least one file').nullable(),
  shareholders: yup.array().min(1, 'You must upload at least one file').nullable(),
  officerslist: yup.array().min(1, 'You must upload at least one file').nullable(),
  authorizationletter: yup.array().min(1, 'You must upload at least one file').nullable(),
  structurechart: yup.array().min(1, 'You must upload at least one file').nullable(),
  groupstructure: yup.array().nullable(),
  sanctions: yup.array().min(1, 'You must upload at least one file').nullable(),
  companylogofiles: yup.array().min(1, 'You must upload at least one file').nullable(),
  // projectreferencefiles: yup.array().min(1, 'You must upload at least one file').nullable(),
});

const statusOptions = [
  { label: 'Needs modification', value: 'DRAFT' },
  { label: 'Accepted', value: 'PREQUALIFIED' },
  { label: 'Rejected', value: 'REJECTED' },
];

export const InititalValuesContext = createContext({
  initialValues: buyerApplicationFormConfig.initialValues,
  updateInitialValues: () => {},
  applicationId: null,
});

function OnChangeFormChangeHandler() {
  const { values } = useFormikContext();
  const { isAdmin } = useAuth();
  const { applicationId } = useContext(InititalValuesContext);
  const [updateForm] = useMutation(EDIT_LOAN_APPLICATION);

  // eslint-disable-next-line no-unused-vars
  const debouncedFormChange = useCallback(
    debounce((formValues, id) => updateForm({
      variables: {
        ...formValues,
        applicationId: id,
      }
    }, 
    ), 150),
    [], // will be created only once initially
  );

  
  const onFormChange = (formValues) => {    
    if (applicationId && !isAdmin){
      debouncedFormChange(formValues, applicationId)
    } 
  };
  
  useEffect(() => {
    onFormChange(values);
  }, [values]);

  return null;
}

function ApplicationFormProvider({ children }) {
  const [initalValuesContext, setInitialValuesContext] = useState({
    initialValues: buyerApplicationFormConfig.initialValues,
    applicationId: null,
    updateInitialValues: (values, applicationId) => {
      setInitialValuesContext({
        ...initalValuesContext,
        applicationId,
        initialValues: values,
      });
    },
  });
  const [commentModalShown, setCommentModalShown] = useState(false);
  const [email, setEmail] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const editorRef = useRef();
  const history = useHistory();

  const [submitApplication] = useMutation(SUBMIT_APPLICATION);
  const [updateApplication] = useMutation(PROCESS_APPLICATION);
  
  // // eslint-disable-next-line no-unused-vars
  // const { applicationId } = useContext(InititalValuesContext);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (values) => {
    submitApplication({ variables: { applicationId: initalValuesContext.applicationId, applicationname: values.applicationname  } });
  };

  const onUpdate = (values) => {
    updateApplication({
      variables: {
        applicationId: initalValuesContext.applicationId,
        state: values.status,
        mailContent: editorRef.current.editor.editor.innerHTML,
      },
      onCompleted: () => history.push('/borrow/admin'),
    });
  };

  return (
    <>
      <InititalValuesContext.Provider value={initalValuesContext}>
        <Formik
          enableReinitialize
          initialValues={initalValuesContext.initialValues}
          validationSchema={ApplicationFormValidationSchema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
            >
              <OnChangeFormChangeHandler />
              {children(setCommentModalShown)}
            </Form>
          )}
        </Formik>
      </InititalValuesContext.Provider>
      <Modal
        show={commentModalShown}
        className="application-modal application-modal--wide"
      >
        <Modal.Body className="p-0">
          <Formik
            initialValues={{ email, title, status }}
            onSubmit={(values) => onUpdate(values)}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleSubmit: handleUpdateStatusSubmit,
            }) => (
              <Form onSubmit={handleUpdateStatusSubmit}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="text-lg">Update loan application status</div>
                  <Button
                    variant="link"
                    onClick={() => setCommentModalShown(false)}
                  >
                    <BsXLg />
                  </Button>
                </div>
                <div className="text-sm text-gray mb-2">
                  Add your comments to the loan application and change its
                  status.The applicant will be notified about the status
                  change with an email containing your comments below.
                </div>
                <div className="text-sm text-gray mb-4">
                  Your comments will be saved when you close this window and
                  navigate through the pages of the loan application. Make sure
                  to only confirm and send the email once you have entered all your comments.
                </div>
                <Field name="status">
                  {({
                    field,
                    meta,
                  }) => (
                    <FormSelect
                      {...field}
                      onChange={(val) => {
                        setFieldValue(field.name, val.value);
                        setStatus(val.value);
                      }}
                      className={meta.touched && meta.error ? 'error' : null}
                      label="Status"
                      options={statusOptions}
                      placeholder="Select status"
                    />
                  )}
                </Field>
                <FormText
                  type="text"
                  name="title"
                  placeholder="e.g. Welcome on board!"
                  onChange={(val) => {
                    setFieldValue('title', val.target.value);
                    setTitle(val.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.title}
                  className={touched.title && errors.title ? 'error' : null}
                  label="Email title"
                />
                <label className="form-label">
                  Email body
                </label>
                <Editor
                  editorState={values.email}
                  ref={editorRef}
                  onEditorStateChange={(editorState) => {
                    setFieldValue('email', editorState);
                    setEmail(editorState);
                  }}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: false },
                    history: { inDropdown: true },
                    embedded: { inDropdown: false },
                  }}
                />
                <Button
                  type="submit"
                  color="primary"
                  className="w-100 mt-4 pt-2 pb-2"
                  // eslint-disable-next-line max-len
                  disabled={!values.title.length || errors.title || !(editorRef?.current?.editor?.editor?.innerText.length > 1)}
                  onClick={handleUpdateStatusSubmit}
                >
                  Confirm and send email
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApplicationFormProvider;
