/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line import/prefer-default-export
export const projects = [];

export const loanApplications = [
  {
    id: 1,
    name: 'Loan application 01',
    status: 'review',
  },
  {
    id: 2,
    name: 'Loan application 02',
    status: 'rejected',
  },
  {
    id: 3,
    name: 'Loan application 03',
    status: 'approved',
  },
  {
    id: 4,
    name: 'Loan application 04',
    status: 'draft',
  },
];

export const loanPools = [];

export const projListButtons = [
  {
    name: 'All projects',
    description: 'All projects seeking funding or already funded',
    state: 'active',
  },
  {
    name: 'Pre-qualified',
    description: 'All projects that have been pre-qualified and are awaiting Due-diligence and Silta scoring',
    state: 'active',
  },
  {
    name: 'Scored by Silta',
    description: 'All projects that have been through due diligence and assigned a Silta Score',
    state: 'active',
  },
  {
    name: 'Financing',
    description: 'All projects where funding terms have been agreed',
    state: 'active',
  },
  {
    name: 'Funded',
    description: 'All projects that have been funded',
    state: 'active',
  },
];

export const financersButtons = [
  {
    name: 'All financiers',
    state: 'active',
  },
  // {
  //   name: 'DeFi',
  //   state: 'active',
  // },
  // {
  //   name: 'DAO',
  //   state: 'active',
  // },
  // {
  //   name: 'TradFi',
  //   state: 'disabled',
  // },
  // {
  //   name: 'Carbon off-takers',
  //   state: 'disabled',
  // },
];

export const financiersInfoTooltips = [
  {
    name: 'Total Value Locked',
    description: 'Total Value Locked is the total amount of funds locked in the smart contracts of the DeFi protocol.',
  },
  {
    name: 'Max loan amount',
    description: 'Max loan amount is the largest amount of principal that the financier is willing to consider. The loan amount of any specific loan is subject to agreement between the financier and the borrower.',
  },
  {
    name: 'Max loan term',
    description: 'Max loan term is the longest possible term to maturity that the financier is willing to consider. The term of any specific loan is subject to agreement between the financier and the borrower.',
  },
];

export const mockProject = [{
  id: 'er2556784od506sfg',
  projectname: 'Handroanthus MG - 75 kW PV plant',
  applicationname: 'Handroanthus MG - 75 kW PV plant',
  companyname: 'COSOL Condominio Solar Ltda',
  state: 'PREQUALIFIED',
  summary: 'A pilot photovoltaic power plant with 75 kW of installed capacity in Minas Gerais state, Brazil. The energy will be supplied to a local SME company already registered by COSOL for a 10 years PPA contract.',
  website: 'https://www.cosol.co',
  linkedin: 'https://www.linkedin.com/company/cosol---condom%C3%ADnio-solar/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: 'The project will deliver a pilot distributed generation photovoltaic power plant the size of 1 MWp in Ouro Preto, Minas Gerais state, Brazil. To be built on a land area of 1,37 hectares, it will consist of 3091 solar modules / panels and 13 inverters (75 kW). The energy will be supplied to a local SME company already registered by COSOL for a 10 years PPA contract. The 75 kW photovoltaic power generation facility would have an estimated greenhouse gas emission reduction of 50 tonnes of CO2 equivalent / year. It would also generate an estimated 690 MWh green electricity / year. In terms of particulate matter emission reductions compared to a gas turbine plant, it would reduce by an estimated 7800 PM/year.',
  projectfinancingtype: 'Senior debt',
  projectloanamount: '80,000 USD',
  projectloanperiod: '8 years',
  projectdeveloperdescription: "COSOL is a Brazilian renewable energy company founded in 2017 with the objective to enable SMEs to access renewable energy by applying the distributed generation rules published by the Brazilian Electricity Regulatory Agency (ANEEL) (REN 482 and 687). It has received awards from EDP Open Innovation, BNDES, SENAI and 100 Open Startups, and funding from the Low Carbon Business Action of the European Union. COSOL's director, Dr. Csaba Sulyok is a business administrator, engineer with PhD in energy and environment (UFBA).",
},
{
  id: 'ev2656799o0d52tae',
  projectname: '1 MW mini solar farm, Cayo de Palma, Colombia',
  applicationname: '1 MW mini solar farm, Cayo de Palma, Colombia',
  companyname: 'Solenium',
  state: 'PREQUALIFIED',
  summary: 'Solenium is working together with Unergy to deliver a 1 MW nominal solar power plant in Cayo de Palma, a small hamlet in the Sucre department in Colombia, whose main activity is the cultivation of cassava and rice. The project is proposed next to the main cassava pulping plant in the town. This project is one of five mini solar farms planned to be installed in isolated areas of Colombia that will work as clean energy generators and provide a total of 5 MW of additional clean energy capacity for use by communities historically affected by poor energy supplies. The project developers aim to deliver a number of additional social benefits to local communities as part of these developments in the areas of employment, education, connectivity and agriculture. Interested financiers have the possibility to bundle these five Solenium and Unergy projects in a single finance transaction.',
  website: 'https://solenium.co/',
  linkedin: 'https://www.linkedin.com/company/soleniumco/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action', 'Partnerships for the goals'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: `
  The Cayo de Palma power plant, located in Sucre, Colombia, is a mini solar farm with an installed capacity of 1,38 MW peak in solar modules and 1 MW nominal power, that will function as a distributed generator connected to the local distribution system. This setup saves the need for the power transmission process and avoids losses in the grid. Solenium will be responsible for constructing the solar energy plant, whilst Unergy audits the construction, sells the energy by representing the plant in the energy market and operates the project during its 30-year life span.
  The energy will be consumed by the community in which the project is developed, feeding an equivalent-average of 1000 homes. Providing the community of Cayo de Palma with a reliable supply of energy. From an economic point of view, the generation of electricity from solar energy involves lower costs than the generation of energy by conventional means, translating into better costs for the consumer. The energy produced will be sold to either an energy trader or to the Grid Operator, who will additionally remunerate the energy losses. 
  Each of the five mini solar farm projects planned to be developed by Solenium and Unergy, including this one, will employ different social impact strategies geared towards providing additional benefits to the local population. These include: 
  • Training and employing local people for the installation and maintenance of the asset; 
  • Ensuring that more than 50% of the personnel involved in the installation of the solar plant are from the local population; 
  • Promoting the dual use of the land by allowing the local people to engage in agricultural activities; 
  • Creating agricultural zones around the farms; 
  • Making the project accessible to local schools, companies, and the general population who are interested in learning about sustainability.`,
  projectfinancingtype: 'Senior debt',
  projectloanamount: '820,000 USD',
  projectloanperiod: '5 years with possibility of refinancing',
  projectdeveloperdescription: 
  `The development of the project is managed by two companies, Solenium and Unergy. 
  Solenium has over 6 years of experience developing solar energy technology and projects with the objective of massifying renewables to save the planet. Solenium has in the past completed projects totalling over 7.6MW and has developed a patented technology that is claimed to increase energy generation by 18% to 25% in solar fields and smart metering technology to measure and optimize energy generation and consumption with Artificial Intelligence. Additionally, Solenium is a certified B corp, due to its commitment to continuous improvement and putting its socio-environmental business purpose at the center of its business model. 
  Unergy is a digital platform and energy provider that utilizes blockchain technology to offer transparent and secure investment opportunities in renewable projects to investors. The company successfully raised $1.3 million in a pre-seed funding round and has been operating in the market for 3 years. Unergy has already helped fund over 30 solar energy projects worth USD $6.5 million. Since its inception in 2020, Unergy has achieved numerous accomplishments on the global stage, including being the runner-up for the Cisco Climate Impact & Regeneration prize, representing Colombia in Silicon Valley through Innpulsa (Colombia´s national entrepreneurship entity), being a global finalist of Accelerate2030, obtaining a license to commercialize energy in the public market in 2021, and tokenizing clean energy assets in 2022.`,
},
{
  id: 'es4656099o0d52tae',
  projectname: '1 MW mini solar farm, Ponedera, Colombia',
  applicationname: '1 MW mini solar farm, Ponedera, Colombia',
  companyname: 'Solenium',
  state: 'PREQUALIFIED',
  summary: 'Solenium is working together with Unergy to deliver a 1 MW nominal solar power plant in Ponedera, Colombia. Ponedera is a municipality in Colombia with 23,000 people and near to Barranquilla, the capital of Atlantico department. This project is one of five mini solar farms planned to be installed in isolated areas of Colombia that will work as clean energy generators and provide a total of 5 MW of additional clean energy capacity for use by communities historically affected by poor energy supplies. The project developers aim to deliver a number of additional social benefits to local communities as part of these developments in the areas of employment, education, connectivity and agriculture. ',
  website: 'https://solenium.co/',
  linkedin: 'https://www.linkedin.com/company/soleniumco/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action', 'Partnerships for the goals'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: `The Ponedera power plant in Colombia, is a mini solar farm with an installed capacity of 1,38 MW peak in solar modules and 1 MW nominal power, that will function as a distributed generator connected to the local distribution system. This setup saves the need for the power transmission process and avoids losses in the grid. Solenium will be responsible for constructing the solar energy plant, whilst Unergy audits the construction, sells the energy by representing the plant in the energy market and operates the project during its 30-year life span. 
  The energy will be consumed by the community in which the project is developed, feeding an equivalent-average of 1000 homes. Providing the community of Ponedera with a reliable supply of energy. From an economic point of view, the generation of electricity from solar energy involves lower costs than the generation of energy by conventional means, translating into better costs for the consumer. The energy produced will be sold to either an energy trader or to the Grid Operator, who will additionally remunerate the energy losses. 
  Each of the five mini solar farm projects planned to be developed by Solenium and Unergy, including this one, will employ different social impact strategies geared towards providing additional benefits to the local population. These include: 
  • Training and employing local people for the installation and maintenance of the asset; 
  • Ensuring that more than 50% of the personnel involved in the installation of the solar plant are from the local population; 
  • Promoting the dual use of the land by allowing the local people to engage in agricultural activities; 
  • Creating agricultural zones around the farms; 
  • Making the project accessible to local schools, companies, and the general population who are interested in learning about sustainability.`,
  projectfinancingtype: 'Senior debt',
  projectloanamount: '820,000 USD',
  projectloanperiod: '5 years with possibility of refinancing',
  projectdeveloperdescription: `The development of the project is managed by two companies, Solenium and Unergy. 
  Solenium has over 6 years of experience developing solar energy technology and projects with the objective of massifying renewables to save the planet. Solenium has in the past completed projects totalling over 7.6MW and has developed a patented technology that is claimed to increase energy generation by 18% to 25% in solar fields and smart metering technology to measure and optimize energy generation and consumption with Artificial Intelligence. Additionally, Solenium is a certified B corp, due to its commitment to continuous improvement and putting its socio-environmental business purpose at the center of its business model. 
  Unergy is a digital platform and energy provider that utilizes blockchain technology to offer transparent and secure investment opportunities in renewable projects to investors. The company successfully raised $1.3 million in a pre-seed funding round and has been operating in the market for 3 years. Unergy has already helped fund over 30 solar energy projects worth USD $6.5 million. Since its inception in 2020, Unergy has achieved numerous accomplishments on the global stage, including being the runner-up for the Cisco Climate Impact & Regeneration prize, representing Colombia in Silicon Valley through Innpulsa (Colombia´s national entrepreneurship entity), being a global finalist of Accelerate2030, obtaining a license to commercialize energy in the public market in 2021, and tokenizing clean energy assets in 2022.`,
},
{
  id: 'es4657399o0d52taw',
  projectname: '1 MW mini solar farm, Alvarado, Colombia',
  applicationname: '1 MW mini solar farm, Alvarado, Colombia',
  companyname: 'Solenium',
  state: 'PREQUALIFIED',
  summary: 'Solenium is working together with Unergy to deliver a 1 MW nominal solar power plant in Alvarado, Tolima, Colombia. This project is one of five mini solar farms planned to be installed in isolated areas of Colombia that will work as clean energy generators and provide a total of 5 MW of additional clean energy capacity for use by communities historically affected by poor energy supplies. The project developers aim to deliver a number of additional social benefits to local communities as part of these developments in the areas of employment, education, connectivity and agriculture. ',
  website: 'https://solenium.co/',
  linkedin: 'https://www.linkedin.com/company/soleniumco/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action', 'Partnerships for the goals'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: `The Baraya power plant, located in Alvarado, Tolima, Colombia, is a mini solar farm with an installed capacity of 1,38 MW peak in solar modules and 1 MW nominal power, that will function as a distributed generator connected to the local distribution system. This setup saves the need for the power transmission process and avoids losses in the grid. Solenium will be responsible for constructing the solar energy plant, whilst Unergy audits the construction, sells the energy by representing the plant in the energy market and operates the project during its 30-year life span. 
  The energy will be consumed by the community in which the project is developed, feeding an equivalent-average of 1000 homes. From an economic point of view, the generation of electricity from solar energy involves lower costs than the generation of energy by conventional means, translating into better costs for the consumer. The energy produced will be sold to either an energy trader or to the Grid Operator, who will additionally remunerate the energy losses. 
  Each of the five mini solar farm projects planned to be developed by Solenium and Unergy, including this one, will employ different social impact strategies geared towards providing additional benefits to the local population. These include:  
  • Training and employing local people for the installation and maintenance of the asset; 
  • Ensuring that more than 50% of the personnel involved in the installation of the solar plant are from the local population; 
  • Promoting the dual use of the land by allowing the local people to engage in agricultural activities; 
  • Creating agricultural zones around the farms; 
  • Making the project accessible to local schools, companies, and the general population who are interested in learning about sustainability.`,
  projectfinancingtype: 'Senior debt',
  projectloanamount: '820,000 USD',
  projectloanperiod: '5 years with possibility of refinancing',
  projectdeveloperdescription: `The development of the project is managed by two companies, Solenium and Unergy. 
  Solenium has over 6 years of experience developing solar energy technology and projects with the objective of massifying renewables to save the planet. Solenium has in the past completed projects totalling over 7.6MW and has developed a patented technology that is claimed to increase energy generation by 18% to 25% in solar fields and smart metering technology to measure and optimize energy generation and consumption with Artificial Intelligence. Additionally, Solenium is a certified B corp, due to its commitment to continuous improvement and putting its socio-environmental business purpose at the center of its business model. 
  Unergy is a digital platform and energy provider that utilizes blockchain technology to offer transparent and secure investment opportunities in renewable projects to investors. The company successfully raised $1.3 million in a pre-seed funding round and has been operating in the market for 3 years. Unergy has already helped fund over 30 solar energy projects worth USD $6.5 million. Since its inception in 2020, Unergy has achieved numerous accomplishments on the global stage, including being the runner-up for the Cisco Climate Impact & Regeneration prize, representing Colombia in Silicon Valley through Innpulsa (Colombia´s national entrepreneurship entity), being a global finalist of Accelerate2030, obtaining a license to commercialize energy in the public market in 2021, and tokenizing clean energy assets in 2022.`,
},
{
  id: 'ez0657399o0d52taz',
  projectname: '1 MW mini solar farm, Luruaco, Colombia',
  applicationname: '1 MW mini solar farm, Luruaco, Colombia',
  companyname: 'Solenium',
  state: 'PREQUALIFIED',
  summary: 'Solenium is working together with Unergy to deliver a 1 MW nominal solar power plant in Luruaco, Atlántico, Colombia. This is the second project in this location and this is one of five mini solar farms planned to be installed in isolated areas of Colombia that will work as clean energy generators and provide a total of 5 MW of additional clean energy capacity for use by communities historically affected by poor energy supplies. It is also one of two mini solar farms planned for development in Luruaco. The project developers aim to deliver a number of additional social benefits to local communities as part of these developments in the areas of employment, education, connectivity and agriculture. ',
  website: 'https://solenium.co/',
  linkedin: 'https://www.linkedin.com/company/soleniumco/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action', 'Partnerships for the goals'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: `The Luruaco 2 power plant, located in Atlántico, Colombia, is a mini solar farm with an installed capacity of 1,38 MW peak in solar modules and 1 MW nominal power, that will function as a distributed generator connected to the local distribution system. This setup saves the need for the power transmission process and avoids losses in the grid. Solenium will be responsible for constructing the solar energy plant, whilst Unergy audits the construction, sells the energy by representing the plant in the energy market and operates the project during its 30-year life span. 
  The energy will be consumed by the community in which the project is developed, feeding an equivalent-average of 1000 homes. Providing the community of Luruaco with a reliable supply of energy. From an economic point of view, the generation of electricity from solar energy involves lower costs than the generation of energy by conventional means, translating into better costs for the consumer. The energy produced will be sold to either an energy trader or to the Grid Operator, who will additionally remunerate the energy losses. 
  Each of the five mini solar farm projects planned to be developed by Solenium and Unergy, including this one, will employ different social impact strategies geared towards providing additional benefits to the local population. These include:  
  • Training and employing local people for the installation and maintenance of the asset; 
  • Ensuring that more than 50% of the personnel involved in the installation of the solar plant are from the local population; 
  • Promoting the dual use of the land by allowing the local people to engage in agricultural activities; 
  • Creating agricultural zones around the farms; 
  • Making the project accessible to local schools, companies, and the general population who are interested in learning about sustainability.`,
  projectfinancingtype: 'Senior debt',
  projectloanamount: '820,000 USD',
  projectloanperiod: '5 years with possibility of refinancing',
  projectdeveloperdescription: `The development of the project is managed by two companies, Solenium and Unergy. 
  Solenium has over 6 years of experience developing solar energy technology and projects with the objective of massifying renewables to save the planet. Solenium has in the past completed projects totalling over 7.6MW and has developed a patented technology that is claimed to increase energy generation by 18% to 25% in solar fields and smart metering technology to measure and optimize energy generation and consumption with Artificial Intelligence. Additionally, Solenium is a certified B corp, due to its commitment to continuous improvement and putting its socio-environmental business purpose at the center of its business model. 
  Unergy is a digital platform and energy provider that utilizes blockchain technology to offer transparent and secure investment opportunities in renewable projects to investors. The company successfully raised $1.3 million in a pre-seed funding round and has been operating in the market for 3 years. Unergy has already helped fund over 30 solar energy projects worth USD $6.5 million. Since its inception in 2020, Unergy has achieved numerous accomplishments on the global stage, including being the runner-up for the Cisco Climate Impact & Regeneration prize, representing Colombia in Silicon Valley through Innpulsa (Colombia´s national entrepreneurship entity), being a global finalist of Accelerate2030, obtaining a license to commercialize energy in the public market in 2021, and tokenizing clean energy assets in 2022.`,
},
{
  id: 'ez0657399o0d54taq',
  projectname: '1 MW mini solar farm, San Diego, Colombia',
  applicationname: '1 MW mini solar farm, San Diego, Colombia',
  companyname: 'Solenium',
  state: 'PREQUALIFIED',
  summary: 'Solenium is working together with Unergy to deliver a 1 MW nominal solar power plant in San Diego, a small municipality near to Valledupar, the capital of Cesar department in Colombia, with one of the highest levels of solar radiation in the country. This project is one of five mini solar farms planned to be installed in isolated areas of Colombia that will work as clean energy generators and provide a total of 5 MW of additional clean energy capacity for use by communities historically affected by poor energy supplies. The project developers aim to deliver a number of additional social benefits to local communities as part of these developments in the areas of employment, education, connectivity and agriculture.',
  website: 'https://solenium.co/',
  linkedin: 'https://www.linkedin.com/company/soleniumco/',
  twitter: null,
  sdggoals: ['Affordable and clean energy', 'Sustainable cities and communities', 'Climate action', 'Partnerships for the goals'],
  projectsector: 'Renewables',
  projecttype: 'Solar farm',
  projecttimetable: 'Project award',
  projectdescription: `The San Diego power plant, located in Atlántico, Colombia,is a mini solar farm with an installed capacity of 1,38 MW peak in solar modules and 1 MW nominal power, that will function as a distributed generator connected to the local distribution system. This setup saves the need for the power transmission process and avoids losses in the grid. Solenium will be responsible for constructing the solar energy plant, whilst Unergy audits the construction, sells the energy by representing the plant in the energy market and operates the project during its 30-year life span. 
  The energy will be consumed by the community in which the project is developed, feeding an equivalent-average of 1000 homes. Providing the community of San Diego with a reliable supply of energy. From an economic point of view, the generation of electricity from solar energy involves lower costs than the generation of energy by conventional means, translating into better costs for the consumer. The energy produced will be sold to either an energy trader or to the Grid Operator, who will additionally remunerate the energy losses.  
  Each of the five mini solar farm projects planned to be developed by Solenium and Unergy, including this one, will employ different social impact strategies geared towards providing additional benefits to the local population. These include:  
  • Training and employing local people for the installation and maintenance of the asset; 
  • Ensuring that more than 50% of the personnel involved in the installation of the solar plant are from the local population; 
  • Promoting the dual use of the land by allowing the local people to engage in agricultural activities; 
  • Creating agricultural zones around the farms; 
  • Making the project accessible to local schools, companies, and the general population who are interested in learning about sustainability.`,
  projectfinancingtype: 'Senior debt',
  projectloanamount: '820,000 USD',
  projectloanperiod: '5 years with possibility of refinancing',
  projectdeveloperdescription: `The development of the project is managed by two companies, Solenium and Unergy. 
  Solenium has over 6 years of experience developing solar energy technology and projects with the objective of massifying renewables to save the planet. Solenium has in the past completed projects totalling over 7.6MW and has developed a patented technology that is claimed to increase energy generation by 18% to 25% in solar fields and smart metering technology to measure and optimize energy generation and consumption with Artificial Intelligence. Additionally, Solenium is a certified B corp, due to its commitment to continuous improvement and putting its socio-environmental business purpose at the center of its business model. 
  Unergy is a digital platform and energy provider that utilizes blockchain technology to offer transparent and secure investment opportunities in renewable projects to investors. The company successfully raised $1.3 million in a pre-seed funding round and has been operating in the market for 3 years. Unergy has already helped fund over 30 solar energy projects worth USD $6.5 million. Since its inception in 2020, Unergy has achieved numerous accomplishments on the global stage, including being the runner-up for the Cisco Climate Impact & Regeneration prize, representing Colombia in Silicon Valley through Innpulsa (Colombia´s national entrepreneurship entity), being a global finalist of Accelerate2030, obtaining a license to commercialize energy in the public market in 2021, and tokenizing clean energy assets in 2022.`,
}
];
