import React from 'react';
import { map } from 'lodash-es';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cosol from '../assets/images/cosol.svg';
import Wenergy from '../assets/images/wenergy_logo.svg';
import Solenium from '../assets/images/solenium.png';
import HelpIcon from '../assets/images/icons/Help-icon-dark.svg';


function Projects({ projects }) {
  return <div className="project-list">
    { map(projects, (project) => {
      const {
        id,
        projectname,
        // companylogofiles,
        companyname,
        state,
        projectsector,
        projectloanamount,
        projecttimetable,
        projectfinancingtype,
        // currency = '$',
      } = project;
        // const currencyMillion = `${currency} ${projectloanamount / 1000000}M`;

      return (
        <Link key={id} className="project-list__project d-flex" to={`/impact-marketplace/project/${id}`}>
          {
            companyname === 'Solenium' && 
            <img
            alt={`${projectname} logo`}
            className="project-list__project__logo h-100 d-inline"
            src={Solenium}
          />
          }

          {
            companyname === 'COSOL Condominio Solar Ltda' && 
            <img
            alt={`${projectname} logo`}
            className="project-list__project__logo h-100 d-inline"
            src={Cosol}
          />
          }
           {
            companyname === 'Archipelago Renewables Corporation' && 
            <img
            alt={`${projectname} logo`}
            className="project-list__project__logo h-100 d-inline"
            src={Wenergy}
          />
          }

          <div className="d-inline-flex flex-column w-100">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <div>
                  <div className="project-list__project__title">
                    {projectname}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="project-list__project__location">
                      {companyname}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-list__project__tag-container">
                <div
                  className={`project-list__project__tag 
                ${state === 'PREQUALIFIED' && 'tag-prequalified tag-prequalified--wide'} 
                ${state === 'VOTING_OPEN' && 'tag-scored tag-scored--wide'} 
                ${state === 'PROJECT_FUNDED' && 'tag-funded tag-funded--wide'}
                ${state === 'FINANCING' && 'tag-financing tag-financing--wide'}
                `}
                >
                  {state === 'PREQUALIFIED' && 'Pre-qualified'}
                  {state === 'VOTING_OPEN' && 'Scored by Silta'}
                  {state === 'PROJECT_FUNDED' && 'Funded'}
                  {state === 'FINANCING' && 'Financing'}

                </div>
                {
                  companyname === 'Solenium' &&
                  <div
                  className='project-list__project__tag tag-bundled' 
                >
                   <span>
                  Bundled 
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="bundled" style={{ opacity: 1 }}>
                     <p className="btn-tooltip-name">
                       This project can be bundled with others Unergy projects
                       </p>
                    <p className="btn-tooltip-description">
                    This project is 1 of 5 projects from the same borrower in a similar asset category. These projects can be bundled as full or different subsets in a single finance transaction. 
                      </p>
                  </Tooltip>
    )}
              >
                <img src={HelpIcon} alt="Help icon" className="tooltip-help ms-1" />
              </OverlayTrigger>
            </span>
                </div>
                }
              </div>
            </div>
            <div className="d-flex justify-content-between project-list__project__info">
              <div className="project-list__project__info__cell">
                <div className="project-list__project__info__label">
                  Loan type
                </div>
                {projectfinancingtype}
              </div>
              <div className="project-list__project__info__cell">
                <div className="project-list__project__info__label">
                  Sector
                </div>
                {projectsector}
              </div>
              <div className="project-list__project__info__cell">
                <div className="project-list__project__info__label">
                  Loan amount
                </div>
                {projectloanamount}
              </div>
              <div className="project-list__project__info__cell">
                <div className="project-list__project__info__label">
                  Project stage
                </div>
                {projecttimetable}
              </div>
            </div>
          </div>
        </Link>
      );
    })}
  </div>
}

export default Projects;
