/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useAuth } from '../providers/AuthProvider';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import { CREATE_APPLICATION, FETCH_LOAN_APPLICATIONS } from '../queries';
import HomeBanner from '../components/HomeBanner';
import NewApplicationsContainer from '../components/NewApplicationsContainer';
import AllApplicationsContainer from '../components/AllApplicationsContainer';

function LoanApplications() {
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(FETCH_LOAN_APPLICATIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const { user, isAdmin } = useAuth();
  const [
    createApplication,
    { loading: creatingApplication, error: createError },
  ] = useMutation(CREATE_APPLICATION, {
    onCompleted: ({ createApplication: createdApplication }) => {
      history.push(`/borrow/application/${createdApplication.id}`);
    },
  });

  const ready = !(loading || creatingApplication || error || createError);
  const loanApplications = data && data.myApplications;

  if (isAdmin) return <Redirect to="/borrow/admin" />;

  return (
    <Layout>
      {!ready ? (
        <Loading padded />
      ) : (
        <section className="loan-application-page container">
          <HomeBanner
            title="Loan applications"
            subtitle="You can view and edit your loan applications here. You will also see any outstanding actions that are required from you in order for us to complete the review process."
          />
          <div className="loan-application-page__welcome">
            Welcome back,
            {' '}
            {user?.username}
          </div>
          <NewApplicationsContainer createApplication={createApplication} />
          <AllApplicationsContainer loanApplications={loanApplications} refetch={refetch} />
        </section>
      )}
    </Layout>
  );
}

export default LoanApplications;
