import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// set RPC urls, infura keys, needed for walletconnect
// eslint-disable-next-line no-unused-vars
const RPC_URLS = {
  1: process.env.RPC_URL_1,
  4: process.env.RPC_URL_4,
};
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/prefer-default-export
export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

/* export const walletconnect = new WalletConnectConnector({
    rpc: { 1: RPC_URLS[1] },
    qrcode: true
}) */
