/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { omit } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { InititalValuesContext } from '../providers/ApplicationForm';
import Layout from '../components/Form/Layout';
// import Loading from '../components/Loading';
import { useAuth } from '../providers/AuthProvider';

import { FETCH_LOAN_APPLICATION, FETCH_ADMIN_APPLICATION } from '../queries';

function ApplicationForm({
  title,
  description,
  index,
  component: FormComponent,
  setCommentModalShown,
}) {
  const { id } = useParams();
  const { updateInitialValues } = useContext(InititalValuesContext);
  const [applicationStateDisabled, setApplicationStateDisabled] = useState(true);
  const { isAdmin } = useAuth();

  const { loading, error } = useQuery(isAdmin ? FETCH_ADMIN_APPLICATION : FETCH_LOAN_APPLICATION, {
    variables: { applicationId: id },
    onCompleted: ({ myApplication, application }) => {
      updateInitialValues(omit(isAdmin ? application : myApplication, ['__typename']), id);
      const state = isAdmin ? application.state : myApplication.state;

      if(state === 'DRAFT' || (!isAdmin && state === 'NEEDS_MODIFICATION')){
        setApplicationStateDisabled(false);
      } else if(isAdmin && state === 'NEEDS_MODIFICATION'){
        setApplicationStateDisabled(true);
      }
    },
    policy: 'cache-and-network',
  });

  const isLoading = loading || error;

  return (
    <Layout
      routeIndex={index}
      applicationStateDisabled={applicationStateDisabled}
      setCommentModalShown={setCommentModalShown}
      loading={isLoading}
    >
      {(isLoading) ? (
        <>
          <div className="silta-form__section">
            <div className="silta-form__skeleton">
              <div className="silta-form__skeleton__item" style={{ width: '40%' }} />
              <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '20%' }} />
            </div>
          </div>
          <fieldset>
            <div className="d-flex">
              <div className="w-100 me-3 mb-4">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '10%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '13%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 me-3 mb-4">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '10%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '80%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '13%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '70%' }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 me-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '15%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '90%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '20%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 me-3 mb-4">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '10%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '13%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 me-3 mb-4">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '10%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '80%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '13%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '70%' }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 me-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '15%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '90%' }} />
              </div>
              <div className="w-100 ms-3">
                <div className="silta-form__skeleton__item silta-form__skeleton__item--small" style={{ width: '20%' }} />
                <div className="silta-form__skeleton__item" style={{ width: '100%' }} />
              </div>
            </div>
          </fieldset>
        </>
      ) : (
        <>
          <div className={`silta-form__section ${index === 5 && 'no_border'}`}>
            <div className="silta-form__title">
              {`${index !== 4 ? index + 1 : index} - `}
              {title}
            </div>
            <div
              className={index < 2 ? 'silta-form__subtitle' : 'silta-form__subtitle-long'}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <fieldset disabled={applicationStateDisabled}>
            <FormComponent applicationStateDisabled={applicationStateDisabled} />
          </fieldset>
        </>
      )}
    </Layout>
  );
}

export default ApplicationForm;
