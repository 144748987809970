import { ErrorMessage } from 'formik';
import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ErrorLabel from '../ErrorMessage';
import Hint from '../../assets/images/icons/help.svg';

function FormText({ type = 'text', id, name, placeholder, onChange, onBlur, value, disabled, className, label, hint, textarea = false, maxLength, wideLabel, tooltip, margin, nomargin }) {
  return <Form.Group controlId={id} className={(textarea && 'form-textarea me-0') || (nomargin && 'me-0')} style={margin && { marginBottom: 28 }}>
    <ErrorMessage name={name} component={ErrorLabel} />
    <Form.Control
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value || undefined}
      as={textarea ? 'textarea' : 'input'}
      style={textarea ? { height: '128px' } : {}}
      className={className}
      disabled={disabled || null}
      maxLength={maxLength || 500}
    />
    <div>
      <Form.Label
        className={wideLabel && 'wide-label'}
      >
        {label}
      </Form.Label>
      {
              tooltip
            && (
            <span className="ms-2" style={{ position: 'relative' }}>
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip style={{ opacity: 1, position: 'absolute', top: 70 }}>
                    <p className="btn-tooltip-name">{tooltip.title}</p>
                    <p className="btn-tooltip-description">{tooltip.text}</p>
                  </Tooltip>
    )}
              >
                <img src={Hint} alt="Help icon" />
              </OverlayTrigger>
            </span>
            )
            }
    </div>
    <div className="form-hint">{hint}</div>
  </Form.Group>
}

export default FormText;
