/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash-es';
import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { CgTrash } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { BsXLg } from 'react-icons/bs';
import { DELETE_APPLICATION, EDIT_LOAN_APPLICATION } from '../queries';
import ButtonNav from './ButtonNav';
import MockImg from '../assets/images/image.svg';
import { FormText } from './Form';

const getActionFromApplicationStatus = (status) => {
  switch (status) {
    case 'REJECTED':
      return 'View application';
    case 'PREQUALIFIED':
      return 'View application';
    case 'WAITING_PREQUALIFICATION':
      return 'View application';
    case 'NEEDS_MODIFICATION':
      return 'Modify';
    case 'DRAFT':
      return 'Continue application';
    default:
      return '';
  }
};

const getLabelForStatus = (status) => {
  switch (status) {
    case 'REJECTED':
      return 'Rejected';
    case 'PREQUALIFIED':
      return 'Prequalified';
    case 'WAITING_PREQUALIFICATION':
      return 'Submitted';
    case 'NEEDS_MODIFICATION':
      return 'Needs modification'
    case 'DRAFT':
      return 'draft';
    default:
      return '';
  }
};

const getClassModifierForStatus = (status) => {
  switch (status) {
    case 'REJECTED':
      return 'rejected';
    case 'PREQUALIFIED':
      return 'prequalified';
    case 'WAITING_PREQUALIFICATION':
      return 'submitted';
    case 'NEEDS_MODIFICATION':
      return 'modification';
    case 'DRAFT':
      return 'draft';
    default:
      return '';
  }
};

function AllApplicationsContainer({ loanApplications, refetch }) {
  const [selected, setSelected] = useState({ name: 'All applications' });
  const [projectsFiltered, setProjectsFiltered] = useState(loanApplications);
  const [id, setId] = useState(null)
  const [editModalShown, setEditModalShown] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const [newName, setNewName] = useState('');
  const [applicationnname, setApplicationnname] = useState('');
  const [updateForm] = useMutation(EDIT_LOAN_APPLICATION);

  const [
    deleteApplication,
  ] = useMutation(DELETE_APPLICATION);

  const handleDeleteApplication = () => {
    deleteApplication({
      variables:{
        applicationId: id
      },
      onCompleted: () => refetch()
    })
  }

  const handleOpenNameModal = (applicname, identifier) => {
    setId(identifier)
    setNewName(applicname);
      setEditModalShown(true);
  }

  const handleOpenDeleteModal = (applicname, identifier) => {
    setNewName(applicname);
    setId(identifier)
    setDeleteModalShown(true);
  }


  const updateApplicationName = () => {
    setApplicationnname(newName);
    updateForm({
      variables: {
        applicationId: id,
        applicationname: newName,
      },
      onCompleted: () => refetch()
    });
    setEditModalShown(false);
  };


  const buttons = [{ name: 'All applications' }, { name: 'Drafts' }, { name: 'Submitted' }, { name: 'Pre-qualified' }, { name: 'Rejected' }];

  const handleClick = (name) => {
    const searchButton = buttons.find((btn) => btn.name === name);
    setSelected(searchButton);

    if (name === 'All applications') {
      setProjectsFiltered(loanApplications);
    } else if (name === 'Drafts') {
      const filtered = loanApplications.filter((proj) => proj.state === 'DRAFT');
      setProjectsFiltered(filtered);
    } else if (name === 'Submitted') {
      const filtered = loanApplications.filter((proj) => proj.state === 'WAITING_PREQUALIFICATION');
      setProjectsFiltered(filtered);
    } else if (name === 'Pre-qualified') {
      const filtered = loanApplications.filter((proj) => proj.state === 'PREQUALIFIED');
      setProjectsFiltered(filtered);
    } else if (name === 'Rejected') {
      const filtered = loanApplications.filter((proj) => proj.state === 'REJECTED');
      setProjectsFiltered(filtered);
    }
  };

  // const getImage = async (application) => {
  //   fetch(`http://localhost:4000/downloadFile/${application?.id}/COMPANY_LOGO/${application?.companylogofiles}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'image',
  //       Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
  //     },
  //   })
  //   // image/png
  //     .then((res) => console.log(res));
  // };

  useEffect(() => {
  }, []);

  useEffect(() => {
  }, [applicationnname, id]);

  return (
    <div>
      <ButtonNav
        handleClick={handleClick}
        buttons={buttons}
        selected={selected.name}
      />

      <div className="loan-applications d-flex flex-wrap mt-5">
        {
          projectsFiltered.length === 0 && <div style={{ height: 184 }} />
        }
        {map(projectsFiltered, (application, index) => (
          <div
            className="application"
            key={index}
          >
            <div className="d-flex justify-content-between">
              {/* <div className='d-flex align-items-center'> */}
             <Link
            className="d-flex align-items-center"
            key={index}
            to={`/borrow/application/${application.id}`}
          >
                <div className="application__image">
                  <img
                    // src={application.companylogofiles.length > 0 ? `${process.env.REACT_APP_FILE_DOWNLOAD_API}/${application.id}/COMPANY_LOGO/${application.companylogofiles}` : MockImg}
                    alt="project logo"
                    src={MockImg}
                  />
                </div>
                <div className="application__title">
                  {application.applicationname && application.applicationname.length ? application.applicationname : (
                    <span>
                      Loan application
                      {' '}
                      {' '}
                      {index + 1}
                    </span>
                  )}
                </div>
              </Link>
              {
                (application.state === 'DRAFT' || application.state === 'NEEDS_MODIFICATION') && 
                 <DropdownButton
                      className="btn-sm p-0 application__dropdown-dots"
                      small="true"
                      variant="link"
                      title=''
                    >
                      <Dropdown.Item
                      className={`edit-dropdown ${application.state === 'NEEDS_MODIFICATION' && 'pe-5'}`}
                      onClick={() => {
                        handleOpenNameModal(application.applicationname, application.id)
                      }}>
                      <FiEdit className='edit-icon' /> Change name
                      </Dropdown.Item>
                      {
                        application.state === 'DRAFT' &&
                          <Dropdown.Item 
                          className='delete-dropdown'
                          onClick={() => {
                            handleOpenDeleteModal(application.applicationname, application.id)
                          }}
                          >
                          <CgTrash className='delete-icon' /> Delete application
                          </Dropdown.Item>
                      }
                    </DropdownButton>
              }
            </div>
            <div className="application__btns-container d-flex align-items-end justify-content-between">
              <div>
                <div className="application__label">Status</div>
                <div
                  className={`application__status application__status--${getClassModifierForStatus(application.state)}`}
                >
                  <div className="application__status__indicator" />
                  {getLabelForStatus(application.state)}
                </div>
              </div>

              <Link
                className="application__link"
                key={index}
                to={`/borrow/application/${application.id}`}
              >
                {getActionFromApplicationStatus(application.state)}
              </Link>
            </div>

          </div>
        ))}
        <Modal
          show={editModalShown}
          onHide={() => setEditModalShown(false)}
          className="application-modal"
        >
          <Modal.Body className="p-0">
            <Formik>
              {({
                errors,
                touched,
                handleBlur,
              }) => (
                <Form>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-lg">Change application name</div>
                    <Button
                      variant="link"
                      className="close-modal-btn"
                      onClick={() => setEditModalShown(false)}
                    >
                      <BsXLg />
                    </Button>
                  </div>
                  <div className="text-sm text-gray mb-4 text-left">Enter the name for this application</div>
                  <FormText
                    type="text"
                    name="applicationname"
                    placeholder=""
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={handleBlur}
                    value={newName || ''}
                    className={`${touched.name && errors.name ? 'error' : null} text-left mb-0`}
                    label="Application name"
                    margin
                  />
                  <Button
                    color="primary"
                    className="w-100 mt-1 mb-2 pt-2 pb-2 confirm-application"
                    onClick={updateApplicationName}
                    disabled={!newName.length || errors.name}
                  >
                    Confirm
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
                <Modal
                  show={deleteModalShown}
                  onHide={() => setDeleteModalShown(false)}
                  className="application-modal"
                >
                  <Modal.Body className="p-0">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="text-lg">Delete application</div>
                      <Button
                        variant="link"
                        className="close-modal-btn"
                        onClick={() => setDeleteModalShown(false)}
                      >
                        <BsXLg />
                      </Button>
                    </div>
                    <div className="text-sm text-gray mb-4 text-left">Are you sure you want to delete <b>{newName}</b> application?
                    <br/> 
                    <p className='mt-2'>This action cannot be undone.</p>
                    </div>
                    <div className='d-flex justify-content-center'>
                      <Button
                        className="w-50 mt-1 mb-2 me-3 pt-2 pb-2 cancel-delete"
                        onClick={() => setDeleteModalShown(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="w-50 mt-1 mb-2 pt-2 pb-2 confirm-delete"
                        onClick={handleDeleteApplication}
                      >
                        Delete
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
      </div>
    </div>
  );
}

export default AllApplicationsContainer;
