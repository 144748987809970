/* eslint-disable max-len */
import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function PrequalBanner() {
  return <div className="prequal-banner blured-shadow d-flex flex-row">
    {/* <div className="mt-3"> */}
      Want to fund your project?
      <Link to="/borrow/loan-applications" className="apply-here">
        Apply here
        <FiArrowUpRight />
      </Link>
    {/* </div> */}
  </div>
}

export default PrequalBanner;
