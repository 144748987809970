import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN } from './providers/AuthProvider';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const errorLink = onError(
  ({ networkError }) => {
    if (String(networkError).includes('ServerError: Response not successful: Received status code 401')) {
      localStorage.clear();
      window.location.reload();
    }
  },
);

const authLink = setContext((_, { head }) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  const headers = {
    ...head,
  };

  if (token) {
    headers.authorization = `bearer ${token}`;
  }

  return { headers };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions,
});

export default client;
