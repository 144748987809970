import React from 'react';
import { Alert } from 'react-bootstrap';

function ErrorAlert({ error }) {
  return <div>
    <Alert variant="danger" className="error-message-login">
      There are errors in this page
      {' '}
      <p>{error}</p>
    </Alert>
  </div>
}

export default ErrorAlert;
