import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ACTIVATE_ACCOUNT } from '../queries';
import Arrow from '../assets/images/arrow_grey_noborder.svg';
import EmailVerified from '../assets/images/icons/email-verified.svg';
// eslint-disable-next-line no-unused-vars

function AccActivate() {
  const { id } = useParams();
  const [active, setActive] = useState(false);

  const [activateAccount, result] = useLazyQuery(ACTIVATE_ACCOUNT);

  useEffect(() => {
    activateAccount({
      variables: { token: id },
    });
  }, [id]);

  useEffect(() => {
    if (result.data?.activate === true) {
      console.log('result.data ', result.data.activate);
      setActive(true);
    }
  }, [result.data]);

  return (
    <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-sm-4 text-center">
            <img className="mb-4" src={EmailVerified} alt="email verified" />
            {
              active
                ? (
                  <>
                    <h2 className="account__title">Email verified</h2>
                    <p className="account__text">Your account has been successfully activated. Click below to log in magically.</p>
                    <div className="mt-3 text-center">
                      <Link to="/login">
                        <Button type="submit" variant="primary" className="account__btn">
                          Continue
                        </Button>
                      </Link>
                    </div>
                    <div className="text-center mb-4 account__resend-email">
                      <span> Didn’t receive the email?</span>
                      {'  '}
                      <Link to="/">Click to resend</Link>
                    </div>
                    <Link to="/login">
                      <img src={Arrow} alt="arrow" />
                      <span className="mx-3 account__backto-login">Back to log in</span>
                    </Link>
                  </>
                )
                : (
                  <>
                    <h2 className="account__title">Please check your email</h2>
                    <p className="account__text">We&apos;ve sent you a confirmation email, please verify your account.</p>
                  </>
                )

            }
          </div>
        </div>
      </div>
  );
}

export default AccActivate;
