/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { map, find } from 'lodash-es';
import { useQuery, useMutation } from '@apollo/client';
import { Dropdown, DropdownButton, Button, Modal } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { Formik, Form, Field } from 'formik';
import { BsXLg } from 'react-icons/bs';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import { FETCH_ALL_LOAN_APPLICATIONS, PROCESS_APPLICATION } from '../queries';
import HomeBanner from '../components/HomeBanner';
import FormSelect from '../components/Form/FormSelect';
import ButtonNav from '../components/ButtonNav';
import { FormText } from '../components/Form';

// const getActionFromApplicationStatus = (status) => {
//   switch (status) {
//     case 'rejected':
//       return 'View application';
//     case 'PREQUALIFIED':
//       return 'View application';
//     case 'WAITING_PREQUALIFICATION':
//       return 'View application';
      // case 'NEEDS_MODIFICATION':
//       return 'Needs modification';
//     case 'DRAFT':
//       return 'Continue application';
//     default:
//       return '';
//   }
// };

const statusOptions = [
  { label: 'Needs modification', value: 'NEEDS_MODIFICATION' },
  { label: 'Accepted', value: 'PREQUALIFIED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Awaiting prequalification', value: 'WAITING_PREQUALIFICATION' },
  { label: 'Expanded', value: 'EXPANDED' },
  { label: 'Staked', value: 'STAKED' },
  { label: 'Scored', value: 'SCORED' },
  { label: 'Sufficient credit score', value: 'SUFFICIENT_CREDIT_SCORE' },
  { label: 'Rejected by borrower', value: 'LOAN_OFFER_REJECTED_BY_BORROWER' },
  { label: 'Voting open', value: 'VOTING_OPEN' },
  { label: 'Voting closed', value: 'VOTING_CLOSED' },
  { label: 'Rejected by community', value: 'LOAN_REJECTED_BY_COMMUNITY' },
  { label: 'Approved by community', value: 'LOAN_APPROVED_BY_COMMUNITY' },
  { label: 'Pool open', value: 'FINANCING' },
  { label: 'Pool closed', value: 'LOAN_POOL_CLOSED' },
  { label: 'Pool unfilled', value: 'LOAN_POOL_NOT_FILLED' },
  { label: 'Pool filled', value: 'LOAN_POOL_FILLED' },
  { label: 'Lent', value: 'COLLATERAL_LENT_TO_BORROWER' },
  { label: 'Funded', value: 'PROJECT_FUNDED' },
];

const getLabelForStatus = (status) => {
  switch (status) {
    case 'REJECTED':
      return 'Rejected';
    case 'PREQUALIFIED':
      return 'Approved';
    case 'WAITING_PREQUALIFICATION':
      return 'In review';
    case 'NEEDS_MODIFICATION':
      return 'Needs modification';
    case 'DRAFT':
      return 'Draft';
    default:
      return find(statusOptions, ({ value }) => value === status).label;
  }
};

const getClassModifierForStatus = (status) => {
  switch (status) {
    case 'REJECTED':
      return 'rejected';
    case 'PREQUALIFIED':
      return 'approved';
    case 'WAITING_PREQUALIFICATION':
      return 'review';
    case 'NEEDS_MODIFICATION':
      return 'draft';
    case 'DRAFT':
      return 'draft';
    default:
      return 'review';
  }
};

function AdminApplications() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(FETCH_ALL_LOAN_APPLICATIONS, {
    fetchPolicy: 'cache-and-network',
  });
  const [commentModalShown, setCommentModalShown] = useState(false)
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [updateApplication] = useMutation(PROCESS_APPLICATION);
  const [statusModalShown, setStatusModalShown] = useState(false);
  const [updatingApplicationId, setUpdatingApplicationId] = useState(null);
  const applications = data && data.allApplications;

  const editorRef = useRef();

  const [selected] = useState('In review');
  const ready = !(loading || error);

  const onUpdate = (values) => {
    updateApplication({
      variables: {
        applicationId: updatingApplicationId,
        state: values.status,
        mailContent: editorRef.current.editor.editor.innerHTML,
      },
      onCompleted: () => {
        // history.push('/borrow/admin'),
        setCommentModalShown(false);
        setUpdatingApplicationId(null);
        refetch();
        setStatus('')
        setEmail('')
        setTitle('')
      }
    });
  };


  return (
    <Layout>
      {!ready ? (
        <Loading padded />
      ) : (
        <section className="loan-application-page container">
            <HomeBanner
              small
              title="Admin panel"
              subtitle="The admin panel allows you to view and comment on all the loan applications that have been submitted to the system."
            />
            <ButtonNav
              handleClick={null}
              buttons={[
                {
                  name: 'In review',
                },
              ]}
              selected={selected}
            />
            {!applications.length && (
              <div className="loan-applications">
                <div className="application__subtitle mt-4 mb-5">
                  There are no applications at this time.
                </div>
              </div>
            )}
            <div className="loan-applications d-flex flex-wrap mt-5">
              {map(applications, (application, index) => (
                <div
                  className="application"
                  key={index}
                >
                  <Link
                    to={`/borrow/application/${application.id}`}
                    className="d-flex align-items-center no-decoration"
                  >
                    <div className="application__image" />
                    <div className="application__title">
                      {application.applicationname && application.applicationname.length ? application.applicationname : (
                        <span>
                          Loan application
                          {' '}
                          {' '}
                          {index + 1}
                        </span>
                      )}
                    </div>
                  </Link>
                  <div className="application__btns-container d-flex align-items-end justify-content-between">
                    <div>
                      <div className="application__label">Status</div>
                      <div
                        className={`application__status application__status--${getClassModifierForStatus(application.state)}`}
                      >
                        <div className="application__status__indicator" />
                        {getLabelForStatus(application.state)}
                      </div>
                    </div>
                    <DropdownButton
                      className="btn-sm p-0 application__dropdown"
                      small="true"
                      variant="link"
                      title="Actions"
                    >
                      <Dropdown.Item href={`/borrow/application/${application.id}`}>
                          View application
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setStatusModalShown(true);
                        setUpdatingApplicationId(application.id);
                        setCommentModalShown(true)
                      }}
                      >
                        Edit status
                      </Dropdown.Item>
                    </DropdownButton>
                    {/* <div className="application__link">
                      {getActionFromApplicationStatus(application.state)}
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </section>
      )}
      <Modal
        show={commentModalShown}
        className="application-modal application-modal--wide"
      >
        <Modal.Body className="p-0">
          <Formik
            initialValues={{ email, title, status }}
            onSubmit={(values) => onUpdate(values)}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleSubmit: handleUpdateStatusSubmit,
            }) => (
              <Form onSubmit={handleUpdateStatusSubmit}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="text-lg">Update loan application status</div>
                  <Button
                    variant="link"
                    onClick={() => setCommentModalShown(false)}
                  >
                    <BsXLg />
                  </Button>
                </div>
                <div className="text-sm text-gray mb-2">
                  Add your comments to the loan application and change its
                  status.The applicant will be notified about the status
                  change with an email containing your comments below.
                </div>
                <div className="text-sm text-gray mb-4">
                  Your comments will be saved when you close this window and
                  navigate through the pages of the loan application. Make sure
                  to only confirm and send the email once you have entered all your comments.
                </div>
                <Field name="status">
                  {({
                    field,
                    meta,
                  }) => (
                    <FormSelect
                      {...field}
                      onChange={(val) => {
                        setFieldValue(field.name, val.value);
                        setStatus(val.value);
                      }}
                      className={meta.touched && meta.error ? 'error' : null}
                      label="Status"
                      options={statusOptions}
                      placeholder="Select status"
                    />
                  )}
                </Field>
                <FormText
                  type="text"
                  name="title"
                  placeholder="e.g. Welcome on board!"
                  onChange={(val) => {
                    setFieldValue('title', val.target.value);
                    setTitle(val.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.title}
                  className={touched.title && errors.title ? 'error' : null}
                  label="Email title"
                />
                <label className="form-label">
                  Email body
                </label>
                <Editor
                  editorState={values.email}
                  ref={editorRef}
                  onEditorStateChange={(editorState) => {
                    setFieldValue('email', editorState);
                    setEmail(editorState);
                  }}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: false },
                    history: { inDropdown: true },
                    embedded: { inDropdown: false },
                  }}
                />
                <Button
                  type="submit"
                  color="primary"
                  className="w-100 mt-4 pt-2 pb-2"
                  // eslint-disable-next-line max-len
                  disabled={!values.title.length || errors.title || !(editorRef?.current?.editor?.editor?.innerText.length > 1)}
                  onClick={handleUpdateStatusSubmit}
                >
                  Confirm and send email
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

    </Layout>
  );
}

export default AdminApplications;
