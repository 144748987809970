import React from 'react';
import { Field, useFormikContext } from 'formik';
import { FormText, FormSelect, FormWeblink } from './index';
import FormPhone from './FormPhone';

const roleOptions = [
  { value: 'ceo', label: 'CEO' },
  { value: 'cfo', label: 'CFO' },
  { value: 'coo', label: 'COO' },
  { value: 'director', label: 'Director' },
  { value: 'other', label: 'Other' },
];

function ContactInformation({ applicationStateDisabled }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div className="d-flex w-100 justify-content-between">
        <Field name="firstname">
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert first name"
              className={meta.touched && meta.error ? 'error' : null}
              label="First name"
            />
          )}
        </Field>
        <Field name="middlename">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert middle name"
              className={meta.touched && meta.error ? 'error' : null}
              label="Middle name"
              hint="Optional"
              nomargin
            />
          )}
        </Field>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <Field name="lastname">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert last name"
              className={meta.touched && meta.error ? 'error' : null}
              label="Last name"
            />
          )}
        </Field>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <Field name="role">
          {({
            field,
            meta,
          }) => (
            <FormSelect
              {...field}
              onChange={(val) => setFieldValue(field.name, val.value)}
              className={meta.touched && meta.error ? 'error' : null}
              label="Your role"
              options={roleOptions}
              placeholder="Select your role"
              disabled={applicationStateDisabled}
            />
          )}
        </Field>
        <Field name="customrole">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Specify your role (if other)"
              className={meta.touched && meta.error ? 'error' : null}
              label="Specify your role (if applicable)"
              disabled={!(values.role === 'other')}
              nomargin
            />
          )}
        </Field>
      </div>
      <Field name="dateofbirth">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert date of birth"
            className={meta.touched && meta.error ? 'error' : null}
            label="Date of birth"
            hint="dd/mm/yyyy"
          />
        )}
      </Field>
      <div className="form-div" style={{ marginTop: 65, marginBottom: 65 }}>
        <Field name="phonenumber">
          {({
            field,
            meta,
          }) => (
            <FormPhone
              {...field}
              placeholder="+34123456789"
              className={meta.touched && meta.error ? 'error' : null}
              label="Phone number"
              hint="Select your country to add the prefix"
              disabled={applicationStateDisabled}
            />
          )}
        </Field>
      </div>
      <Field name="email">
        {({
          field,
          meta,
        }) => (
          <FormText
            type="email"
            {...field}
            placeholder="Insert email"
            className={meta.touched && meta.error ? 'error' : null}
            label="Email address"
          />
        )}
      </Field>
      <Field name="linkedin">
        {({
          field,
          meta,
        }) => (
          <FormWeblink
            {...field}
            placeholder="www.linkedin.com/handle"
            className={meta.touched && meta.error ? 'error' : null}
            label="LinkedIn profile"
          />
        )}
      </Field>
    </>
  );
}

export default ContactInformation;
