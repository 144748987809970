import React from 'react';

function ErrorMessage({ children, className }) {
  return (
    <div className={className || 'error-message'}>
      {children}
    </div>
  );
}

export default ErrorMessage;
