/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { map } from 'lodash-es';
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { BsPlusLg } from 'react-icons/bs';
import { FormText, FormCheck, FormCurrency, FormWeblink } from './index';
import ErrorLabel from '../ErrorMessage';
import FormPercentage from './FormPercentage';

const projectPhaseOptions = ['Concept', 'Feasibility study', 'Project award', 'Contract', 'Financial close', 'Construction', 'Other'];
const projectModalityOptions = ['Government PPP scheme', 'Private investment'];
const governmentSupport = ['Yes', 'No'];
const projectPhaseOptionsTooltips = [
  { title: 'Concept', text: 'The concept exists, but you have not yet completed your own feasibility study or carried out bidding on the project (if tendered).' },
  { title: 'Feasibility study', text: 'You have completed a feasibility study and determined that the project is technically and financially feasible.' },
  { title: 'Project award', text: 'You have been approved as the developer for the project (by winning the bid or otherwise). You are are now in the process of negotiating or finalizing the key contracts for implementing the project.' },
  { title: 'Contract', text: 'You have executed all the key project contracts that are needed to allow negotiation with lenders.' },
  { title: 'Financial close', text: 'You have executed the loan agreement(s) and have satisfied all conditions for drawing down on the loan.' },
  { title: 'Construction', text: 'You have issued the notice to proceed for the construction of the project and have a set date for completing the construction.' },
];
// const projectModalityOptionsTooltips = [
//   { title: 'Government PPP scheme', text: 'The concept exists, but you have not yet completed your own feasibility study or carried out bidding on the project (if tendered).' },
//   { title: 'Private investment', text: 'You have completed a feasibility study and determined that the project is technically and financially feasible.' },
// ];

function ProjectInformation({ applicationStateDisabled }) {
  const { values, setFieldValue } = useFormikContext();

  const newRelevantProjectLink = () => {
    setFieldValue('projectrelevantlinks', values.projectrelevantlinks ? [...values.projectrelevantlinks, ''] : ['']);
  };

  const newTwitterLink = () => {
    setFieldValue('projectLeadershipTwitter', values.projectLeadershipTwitter ? [...values.projectLeadershipTwitter, ''] : ['']);
  };

  const newLinkedInLink = () => {
    setFieldValue('projectLeadershipLinkedIn', values.projectLeadershipLinkedIn ? [...values.projectLeadershipLinkedIn, ''] : ['']);
  };

  if (!values.projectrelevantlinks || !values.projectrelevantlinks.length) newRelevantProjectLink();
  if (!values.projectLeadershipTwitter || !values.projectLeadershipTwitter.length) newTwitterLink();
  if (!values.projectLeadershipLinkedIn || !values.projectLeadershipLinkedIn.length) newLinkedInLink();

  return (
    <div>
      <Field name="projecttotalcapital">
        {({
          field,
          meta,
        }) => (
          <div className="position-relative">
            <FormCurrency
              {...field}
              placeholder="30,000,000"
              className={meta.touched && meta.error ? 'error' : null}
              textarea
              onChange={(e) => setFieldValue(field.name, e.target.value)}
              label="Total capital required for the project"
              hint="Specify amount and currency"
              disabled={applicationStateDisabled}
            />
          </div>
        )}
      </Field>

      <div style={{ marginTop: 60 }}>
        <Field name="projectloanamount">
          {({
            field,
            meta,
          }) => (
            <div className="position-relative">
              <FormCurrency
                {...field}
                placeholder="20,000,000"
                className={meta.touched && meta.error ? 'error' : null}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
                textarea
                label="Loan amount"
                hint="Specify amount and currency"
                disabled={applicationStateDisabled}
              />
            </div>
          )}
        </Field>
      </div>

      <div style={{ marginTop: 70 }}>
        <Field name="projectloanperiod">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert text"
              className={meta.touched && meta.error ? 'error' : null}
              label="Preferred loan term"
              hint="Specify number of years and/or months"
              onChange={(e) => setFieldValue(field.name, e.target.value)}
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 70 }}>
        <Field name="projectinterestrate">
          {({
            field,
            meta,
          }) => (
            <FormPercentage
              {...field}
              className={meta.touched && meta.error ? 'error' : null}
              onChange={(e) => setFieldValue(field.name, e.target.value)}
              label="Interest rate that the company is expecting to pay"
              hint="Specify APR as percentage"
              disabled={applicationStateDisabled}
              placeholder="5.0"
              wideLabel
            />
          )}
        </Field>
      </div>
      <div className="form-separator" />
      <h6 className="mb-5">PROJECT TIMETABLE</h6>
      <Field name="projecttimetable">
        {({
          field,
          meta,
        }) => (
          <>
            <label className="form-label mb-3">
              Select the current stage of the project
            </label>
            {map(projectPhaseOptions, (type, index) => (
              <FormCheck
                key={index}
                id={type + index}
                {...field}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
                type="radio"
                hint={projectPhaseOptionsTooltips[index]}
                className={`${meta.touched && meta.error ? 'error-radio' : null} ${index + 1 === projectPhaseOptions.length ? 'mb-0' : 'mb-2'}`}
                label={type}
                classNameCheck="no-bg"
              />
            ))}
          </>
        )}
      </Field>
      <Field name="alternateprojecttimetable">
        {({
          field,
          meta,
        }) => (
          <FormText
            {...field}
            placeholder="Insert phase"
            className={meta.touched && meta.error ? 'error' : null}
            label=""
            hint="If other, specify the current stage here"
            disabled={values.projecttimetable !== 'Other'}
          />
        )}
      </Field>
      <ErrorMessage name="projecttimetable" component={ErrorLabel} className="checkbox-error" />
      <ErrorMessage name="alternateprojecttimetable" component={ErrorLabel} className="checkbox-error" />
      <div style={{ marginTop: 70 }}>
        <Field name="projectConstructionStartDate">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert date (mm/yyyy)"
              className={meta.touched && meta.error ? 'error' : null}
              label="Expected start date of the construction stage"
              hint="mm/yyyy"
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 70 }}>
        <Field name="projectOperationsStartDate">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert date (mm/yyyy)"
              className={meta.touched && meta.error ? 'error' : null}
              label="Expected start date of the operational stage"
              hint="mm/yyyy"
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 70, marginBottom: 80 }}>
        <Field name="projectOperationsLength">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert text"
              className={meta.touched && meta.error ? 'error' : null}
              label="Length of the operational stage"
              hint="Years and months"
            />
          )}
        </Field>
      </div>
      <div className="form-separator" />
      <h6 className="mb-5 mt-5">PROJECT MODALITY</h6>
      <label className="form-label mb-3">
        Is this part of a government PPP scheme? Or is it a private investment?
      </label>
      <ErrorMessage name="projectmodality" component={ErrorLabel} className="checkbox-error" />
      <Field name="projectmodality">
        {({
          field,
          meta,
        }) => (
          <>
            {map(projectModalityOptions, (type, index) => (
              <FormCheck
                key={index}
                id={type + index}
                {...field}
                // hint={projectModalityOptionsTooltips[index]}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
                type="radio"
                className={`${meta.touched && meta.error && 'error-radio'} ${index + 1 === projectModalityOptions.length ? 'mb-0' : 'mb-2'}`}
                label={type}
                classNameCheck="no-bg"
              />
            ))}
          </>
        )}
      </Field>
      <div style={{ marginTop: 50 }}>
        <Field name="governmentsupport">
          {({
            field,
            meta,
          }) => (
            <>
              <label className="form-label w-50 mb-3">
                Is there any form of government support, such as government guarantees or state contribution involved?
              </label>
              {map(governmentSupport, (type, index) => (
                <FormCheck
                  {...field}
                  key={`govsupport${index}`}
                  id={`govsupport${index}`}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                  type="radio"
                  className={`${meta.touched && meta.error && 'error-radio'} ${index + 1 === governmentSupport.length ? 'mb-0' : 'mb-2'}`}
                  label={type}
                  classNameCheck="no-bg"
                />
              ))}
            </>
          )}
        </Field>
      </div>
      <div style={{ marginBottom: 80 }}>
        <Field name="governmentsupportdescription">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="Insert text"
              className={meta.touched && meta.error ? 'error' : null}
              label=""
              hint="If yes, please describe. Max 1000 characters"
              disabled={values.governmentsupport !== 'Yes'}
              textarea
              maxLength="1000"
            />
          )}
        </Field>
        <ErrorMessage name="governmentsupport" component={ErrorLabel} className="checkbox-error" />
        <ErrorMessage name="governmentsupportdescription" component={ErrorLabel} className="checkbox-error" />
      </div>
      <div className="form-separator" />
      <div style={{ marginTop: 60 }}>
        <Field name="projectirr">
          {({
            field,
            meta,
          }) => (
            <FormText
              {...field}
              placeholder="E.g. 17%"
              className={meta.touched && meta.error ? 'error' : null}
              label="Expected IRR"
              hint="Indicate the expected rate of return, on a fully leveraged basis, as per your completed financial models. You may be required to share those models with us before the final decision on the loan is made."
            />
          )}
        </Field>
      </div>
      <div style={{ marginTop: 80 }}>
        <Field name="projectequitydebtratio" className="mt-5">
          {({
            field,
            meta,
          }) => (
            <div className="pt-5">
              <FormText
                {...field}
                placeholder="E.g. 20% equity / 80% debt"
                className={meta.touched && meta.error ? 'error' : null}
                label="Equity / debt ratio"
                hint="Indicate the equity / debt ratio, as percentage, taking into account the loan amount in this application."
              />
            </div>
          )}
        </Field>
      </div>
      <div className="pt-2">
        <div className="form-separator mt-5" />
      </div>
      <div className="d-flex justify-content-between">
        <div className="col-6 form-group">
          {values.projectLeadershipTwitter && values.projectLeadershipTwitter.length && values.projectLeadershipTwitter.map((link, index) => (
            <div key={`link-${index}`}>
              <Field name="projectLeadershipTwitter">
                {({
                  field,
                  meta,
                }) => (
                  <FormWeblink
                    {...field}
                    placeholder="www.twitter.com/handle"
                    className={meta.touched && meta.error ? 'error' : null}
                    label="Twitter profile for the leadership team"
                    hint="Optional"
                    disabled={applicationStateDisabled}
                    value={link}
                    onChange={(e) => setFieldValue(field.name, map(values.projectLeadershipTwitter, (f, idx) => {
                      if (idx === index) return e.target.value;
                      return f;
                    }))}
                  />
                )}
              </Field>
            </div>
          ))}

          <button type="button" className="add-profile d-flex justify-content-start" onClick={() => !applicationStateDisabled && newTwitterLink()}>
            <BsPlusLg />
            <span className="form-text"> Add another twitter profile</span>
          </button>
        </div>
        <div className="col-6 form-group me-0">
          {values.projectLeadershipLinkedIn && values.projectLeadershipLinkedIn.length && values.projectLeadershipLinkedIn.map((link, index) => (
            <div key={`link-${index}`}>
              <Field name="projectLeadershipLinkedIn">
                {({
                  field,
                  meta,
                }) => (
                  <FormWeblink
                    {...field}
                    placeholder="www.linkedin.com/name"
                    className={meta.touched && meta.error ? 'error' : null}
                    label="LinkedIn profile for the leadership team"
                    hint="Optional"
                    disabled={applicationStateDisabled}
                    value={link}
                    onChange={(e) => setFieldValue(field.name, map(values.projectLeadershipLinkedIn, (f, idx) => {
                      if (idx === index) return e.target.value;
                      return f;
                    }))}
                  />
                )}
              </Field>
            </div>
          ))}
          <button type="button" className="add-profile d-flex justify-content-start" onClick={() => !applicationStateDisabled && newLinkedInLink()}>
            <BsPlusLg />
            <span className="form-text"> Add another LinkedIn profile</span>
          </button>
        </div>
      </div>

      <div className="form-separator mt-5" />

      <div className="d-flex justify-content-between flex-wrap">
        {values.projectrelevantlinks.length && values.projectrelevantlinks.map((link, index) => (
          <div key={`link-${index}`} className={`col-6 form-group mb-0 ${index % 2 === 1 && 'me-0 pe-0'}`}>
            <Field name="projectrelevantlinks">
              {({
                field,
                meta,
              }) => (
                <FormWeblink
                  {...field}
                  placeholder="www.website.com"
                  className={meta.touched && meta.error ? 'error' : null}
                  label="Other relevant project links"
                  hint="Optional"
                  disabled={applicationStateDisabled}
                  value={link}
                  onChange={(e) => setFieldValue(field.name, map(values.projectrelevantlinks, (f, idx) => {
                    if (idx === index) return e.target.value;
                    return f;
                  }))}
                />
              )}
            </Field>
          </div>
        ))}
      </div>
      <div className="col-6">
        <button type="button" className="add-profile d-flex justify-content-start" onClick={() => !applicationStateDisabled && newRelevantProjectLink()}>
          <BsPlusLg />
          <span className="form-text"> Add another link</span>
        </button>
      </div>
    </div>
  );
}

export default ProjectInformation;
