import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useAuth } from '../providers/AuthProvider';
import Logo from '../assets/images/icons/signin-logo.svg';
import FormText from '../components/Form/FormText';
import ErrorAlert from '../components/ErrorAlert';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Invalid email format')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be at least 8 characters')
    .required('Password is required'),
});

function Login() {
  const auth = useAuth();

  return (
    <section className="account">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-5">
            <div className="w-100 text-center">
              <Link to="/">
                <img src={Logo} alt="Silta logo" className="account__logo" />
              </Link>
              <h2 className="account__title">Log in to your account</h2>
              <p className="account__text">
                Welcome back! Please enter your details.
              </p>
            </div>
            <div className="w-100">
              {auth.loginError && <ErrorAlert error={auth.loginError} />}
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={(values) => auth.signin(values)}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormText
                      id="formEmail"
                      label="Email"
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={touched.email && errors.email ? 'error' : null}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <FormText
                      id="formPassword"
                      label="Password"
                      type="Password"
                      name="password"
                      placeholder="Password"
                      className={touched.password && errors.password ? 'error' : null}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />

                    <Link style={{ float: 'right' }} className="d-flex text-sm" to="/forgot-password">Forgot password?</Link>

                    <div className="mb-3 text-center">
                      <Button
                        type="submit"
                        variant="primary"
                        className="account__btn mt-3"
                      >
                        Sign in
                      </Button>
                    </div>
                    <div className="signup-btn text-center">
                      Don&apos;t have an account?&nbsp;
                      <Link to="/sign-up">Sign up</Link>
                    </div>
                    <div className="login-back w-100 d-flex align-items-center justify-content-center">
                      <Link to="/impact-marketplace">
                        <BsArrowLeftShort />
                        Back
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
